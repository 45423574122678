import {
  AudioType,
  ColorLayer,
  ColorTab,
  ColorType,
  DirectionType,
  ElementType,
  ExistingScribeModel,
  HideEventTrigger,
  ImageCategory,
  UpgradeButtonClickExtraEventProps,
  TemplatePreview,
  TextAlignValue,
  UploadProvider,
  UserPersonaAnswers,
  AccountType,
  AccountTerm
} from 'js/types';

export const TRACK_PERSONA_ANSWERS = 'TRACK_PERSONA_ANSWERS';
export const TRACK_OPEN_ACCOUNT_MANAGEMENT = 'TRACK_OPEN_ACCOUNT_MANAGEMENT';
export const TRACK_TEMPLATE_PREVIEW_CLICK = 'TRACK_TEMPLATE_PREVIEW_CLICK';
export const LEAVE_TEMPLATE_PREVIEW = 'LEAVE_TEMPLATE_PREVIEW';
export const TRACK_TEMPLATE_SCROLL = 'TRACK_TEMPLATE_SCROLL';
export const TRACK_TEMPLATE_EDIT = 'TRACK_TEMPLATE_EDIT';
export const TRACK_DOWNLOAD_FORMAT_SELECTION_CHANGE = 'TRACK_DOWNLOAD_FORMAT_SELECTION_CHANGE';
export const TRACK_CURSOR_SELECTION_CHANGE = 'TRACK_CURSOR_SELECTION_CHANGE';
export const TRACK_CURSOR_FILTER_CHANGE = 'TRACK_CURSOR_FILTER_CHANGE';
export const TRACK_IMAGE_IMPORT_CLICK = 'TRACK_IMAGE_IMPORT_CLICK';
export const TRACK_IMAGE_LIBRARY_CLICK = 'TRACK_IMAGE_LIBRARY_CLICK';
export const TRACK_IMAGE_PROVIDER_CHANGE = 'TRACK_IMAGE_PROVIDER_CHANGE';
export const TRACK_IMAGE_SEARCH = 'TRACK_IMAGE_SEARCH';
export const TRACK_CURSOR_SETTINGS_OPEN = 'TRACK_CURSOR_SETTINGS_OPEN';
export const TRACK_OPEN_DOWNLOAD = 'TRACK_OPEN_DOWNLOAD';
export const TRACK_UPDATE_SCRIBE_BACKGROUND_SETTINGS = 'TRACK_UPDATE_SCRIBE_BACKGROUND_SETTINGS';
export const TRACK_AUDIO_IMPORT_CLICK = 'TRACK_AUDIO_IMPORT_CLICK';
export const TRACK_AUDIO_RECORDING_CANCEL = 'TRACK_AUDIO_RECORDING_CANCEL';
export const TRACK_PLAY_SCRIBE = 'TRACK_PLAY_SCRIBE';
export const TRACK_OPEN_SCRIBE = 'TRACK_OPEN_SCRIBE';
export const TRACK_FREE_TRANSFORM_SELECTED = 'TRACK_FREE_TRANSFORM_SELECTED';
export const TRACK_FLIP_ELEMENT = 'TRACK_FLIP_ELEMENT';
export const TRACK_SELECT_FONT = 'TRACK_SELECT_FONT';
export const TRACK_ALIGN_TEXT = 'TRACK_ALIGN_TEXT';
export const TRACK_BOLD_TEXT = 'TRACK_BOLD_TEXT';
export const TRACK_ITALIC_TEXT = 'TRACK_ITALIC_TEXT';
export const TRACK_CHANGE_ELEMENT_COLOR = 'TRACK_CHANGE_ELEMENT_COLOR';
export const TRACK_DOWNLOAD_PROGRESS_HIDDEN = 'TRACK_DOWNLOAD_PROGRESS_HIDDEN';
export const TRACK_FULLSCREEN_ENTERED = 'TRACK_FULLSCREEN_ENTERED';
export const TRACK_SELECT_ALL = 'TRACK_SELECT_ALL';
export const TRACK_SHARE_PROJECT = 'TRACK_SHARE_PROJECT';
export const TRACK_PREVIEW_LINK_COPY = 'TRACK_PREVIEW_LINK_COPY';
export const TRACK_IMAGE_REPLACE_MODAL = 'TRACK_IMAGE_REPLACE_MODAL';
export const TRACK_IMAGE_REPLACE = 'TRACK_IMAGE_REPLACE';
export const TRACK_SET_ACTIVE_ELEMENTS_VISIBILITY = 'TRACK_SET_ACTIVE_ELEMENTS_VISIBILITY';
export const TRACK_SEARCH_BY_PROJECT_TITLE = 'TRACK_SEARCH_BY_PROJECT_TITLE';
export const TRACK_CREATE_NEW_PROJECT = 'TRACK_CREATE_NEW_PROJECT';
export const TRACK_TIMELINE_ZOOM_EXPANDED = 'TRACK_TIMELINE_ZOOM_EXPANDED';
export const TRACK_TIMELINE_SCROLL_TO_BEGINNING = 'TRACK_TIMELINE_SCROLL_TO_BEGINNING';
export const TRACK_TIMELINE_SCROLL_TO_END = 'TRACK_TIMELINE_SCROLL_TO_END';
export const TRACK_OPEN_RECORD_AUDIO_MODAL = 'TRACK_OPEN_RECORD_AUDIO_MODAL';
export const TRACK_OPEN_UPLOAD_AUDIO_MODAL = 'TRACK_OPEN_UPLOAD_AUDIO_MODAL';
export const TRACK_PREVIEW_AUDIO = 'TRACK_PREVIEW_AUDIO';
export const TRACK_SEARCH_AUDIO_FILES = 'TRACK_SEARCH_AUDIO_FILES';
export const TRACK_SELECT_FILTER_AUDIO_FILES = 'TRACK_SELECT_FILTER_AUDIO_FILES';
export const TRACK_FILTER_TEMPLATES_BY_CATEGORY = 'TRACK_FILTER_TEMPLATES_BY_CATEGORY';
export const TRACK_CAMERA_WARNING_MODAL = 'TRACK_CAMERA_WARNING_MODAL';
export const TRACK_UPGRADE_SUBSCRIPTION_BUTTON_CLICKED = 'TRACK_UPGRADE_SUBSCRIPTION_BUTTON_CLICKED';
export const TRACK_PURCHASE_SUBSCRIPTION_BUTTON_CLICKED = 'TRACK_PURCHASE_SUBSCRIPTION_BUTTON_CLICKED';
export const TRACK_HIT_DOWNLOAD_LIMIT = 'TRACK_HIT_DOWNLOAD_LIMIT';
export const TRACK_HIT_PROJECT_DURATION_LIMIT = 'TRACK_HIT_PROJECT_DURATION_LIMIT';
export const TRACK_HIT_PROJECT_DURATION_LIMIT_MODAL_DISPLAYED = 'TRACK_HIT_PROJECT_DURATION_LIMIT_MODAL_DISPLAYED';
export const TRACK_NO_ACCESS_FEATURE_MODAL_DISPLAYED = 'TRACK_NO_ACCESS_FEATURE_MODAL_DISPLAYED';
export const TRACK_SPLIT_AUDIO_TRACK = 'TRACK_SPLIT_AUDIO_TRACK';
export const TRACK_AUDIO_SPLIT_MODE_CANCEL = 'TRACK_AUDIO_SPLIT_MODE_CANCEL';
export const TRACK_CLICK_ENABLE_COLOUR_CHANGE_ON_IMAGE = 'TRACK_CLICK_ENABLE_COLOUR_CHANGE_ON_IMAGE';
export const TRACK_ENABLE_COLOUR_CHANGE_INFO_MODAL_DISPLAYED = 'TRACK_ENABLE_COLOUR_CHANGE_INFO_MODAL_DISPLAYED';
export const TRACK_CONFIRM_COLOUR_CHANGE_ON_IMAGE = 'TRACK_CONFIRM_COLOUR_CHANGE_ON_IMAGE';
export const TRACK_IMAGE_COLOUR_CHANGE_SUCCESSFUL = 'TRACK_IMAGE_COLOUR_CHANGE_SUCCESSFUL';

export interface TrackClickEnableColourChangeOnImageAction {
  type: typeof TRACK_CLICK_ENABLE_COLOUR_CHANGE_ON_IMAGE;
  scribeId: number;
}
export const trackClickEnableColourChangeOnImage = (scribeId: number): TrackClickEnableColourChangeOnImageAction => ({
  type: TRACK_CLICK_ENABLE_COLOUR_CHANGE_ON_IMAGE,
  scribeId
});

export interface TrackEnableColourChangeInfoModalDisplayedAction {
  type: typeof TRACK_ENABLE_COLOUR_CHANGE_INFO_MODAL_DISPLAYED;
  scribeId: number;
}
export const trackEnableColourChangeInfoModalDisplayed = (
  scribeId: number
): TrackEnableColourChangeInfoModalDisplayedAction => ({
  type: TRACK_ENABLE_COLOUR_CHANGE_INFO_MODAL_DISPLAYED,
  scribeId
});

export interface TrackConfirmColourChangeOnImageAction {
  type: typeof TRACK_CONFIRM_COLOUR_CHANGE_ON_IMAGE;
  scribeId: number;
}
export const trackConfirmColourChangeOnImage = (scribeId: number): TrackConfirmColourChangeOnImageAction => ({
  type: TRACK_CONFIRM_COLOUR_CHANGE_ON_IMAGE,
  scribeId
});

export interface TrackImageColourChangeSuccessfulAction {
  type: typeof TRACK_IMAGE_COLOUR_CHANGE_SUCCESSFUL;
  scribeId: number;
}
export const trackImageColourChangeSuccessful = (scribeId: number): TrackImageColourChangeSuccessfulAction => ({
  type: TRACK_IMAGE_COLOUR_CHANGE_SUCCESSFUL,
  scribeId
});

export interface TrackHitDownloadLimitAction {
  type: typeof TRACK_HIT_DOWNLOAD_LIMIT;
  scribeId: number;
}

export const trackHitDownloadLimit = (scribeId: number): TrackHitDownloadLimitAction => ({
  type: TRACK_HIT_DOWNLOAD_LIMIT,
  scribeId
});

export interface TrackHitProjectDurationLimitAction {
  type: typeof TRACK_HIT_PROJECT_DURATION_LIMIT;
  scribeId: number;
}

export const trackHitProjectDurationLimit = (scribeId: number): TrackHitProjectDurationLimitAction => ({
  type: TRACK_HIT_PROJECT_DURATION_LIMIT,
  scribeId
});

export interface TrackHitProjectDurationLimitModalDisplayedAction {
  type: typeof TRACK_HIT_PROJECT_DURATION_LIMIT_MODAL_DISPLAYED;
  scribeId: number;
}

export const trackHitProjectDurationLimitModalDisplayed = (
  scribeId: number
): TrackHitProjectDurationLimitModalDisplayedAction => ({
  type: TRACK_HIT_PROJECT_DURATION_LIMIT_MODAL_DISPLAYED,
  scribeId
});

export interface TrackPersonalAnswersAction {
  type: typeof TRACK_PERSONA_ANSWERS;
  answers: UserPersonaAnswers;
  date: Date;
  userTriggered: boolean;
}

export const trackPersonaAnswers = (
  answers: UserPersonaAnswers,
  date: Date,
  userTriggered = false
): TrackPersonalAnswersAction => ({
  type: TRACK_PERSONA_ANSWERS,
  answers,
  date,
  userTriggered
});

export const trackOpenAccountManagement = () => ({ type: TRACK_OPEN_ACCOUNT_MANAGEMENT });
export const trackAudioRecordingCancel = () => ({ type: TRACK_AUDIO_RECORDING_CANCEL });

export interface TrackTemplatePreviewClickAction {
  type: typeof TRACK_TEMPLATE_PREVIEW_CLICK;
  template: TemplatePreview;
  category: string;
}

export const trackTemplatePreviewClick = (
  template: TemplatePreview,
  category: string
): TrackTemplatePreviewClickAction => ({
  type: TRACK_TEMPLATE_PREVIEW_CLICK,
  template,
  category
});

export interface LeaveTemplatePreviewAction {
  type: typeof LEAVE_TEMPLATE_PREVIEW;
}

export const leaveTemplatePreview = (): LeaveTemplatePreviewAction => ({
  type: LEAVE_TEMPLATE_PREVIEW
});

export interface TrackTemplateScrollAction {
  type: typeof TRACK_TEMPLATE_SCROLL;
  category: string;
}

export const trackTemplateScroll = (category: string): TrackTemplateScrollAction => ({
  type: TRACK_TEMPLATE_SCROLL,
  category
});

export interface TrackTemplateEditAction {
  type: typeof TRACK_TEMPLATE_EDIT;
  title: string;
  category: string;
  triggerLocation: string;
  sparkolTemplateId: string;
}

export const trackTemplateEdit = (
  title: string,
  category: string,
  triggerLocation: string,
  sparkolTemplateId: string
): TrackTemplateEditAction => ({
  type: TRACK_TEMPLATE_EDIT,
  title,
  category,
  triggerLocation,
  sparkolTemplateId
});

export interface TrackDownloadFormatSelectionChangeAction {
  type: typeof TRACK_DOWNLOAD_FORMAT_SELECTION_CHANGE;
  format: string;
  scribe: ExistingScribeModel;
}

export const trackDownloadFormatSelectionChange = (
  format: string,
  scribe: ExistingScribeModel
): TrackDownloadFormatSelectionChangeAction => ({
  type: TRACK_DOWNLOAD_FORMAT_SELECTION_CHANGE,
  format,
  scribe
});

export interface TrackCursorSelectionChangeAction {
  type: typeof TRACK_CURSOR_SELECTION_CHANGE;
  cursorId: number;
  scribeId: number;
}

export const trackCursorSelectionChange = (cursorId: number, scribeId: number): TrackCursorSelectionChangeAction => ({
  type: TRACK_CURSOR_SELECTION_CHANGE,
  cursorId,
  scribeId
});

export interface TrackCursorFilterChangeAction {
  type: typeof TRACK_CURSOR_FILTER_CHANGE;
  category: string;
  scribeId: number;
}

export const trackCursorFilterChange = (category: string, scribeId: number): TrackCursorFilterChangeAction => ({
  type: TRACK_CURSOR_FILTER_CHANGE,
  category,
  scribeId
});

export interface TrackImageImportClickAction {
  type: typeof TRACK_IMAGE_IMPORT_CLICK;
  scribeId: number;
}

export const trackImageImportClick = (scribeId: number): TrackImageImportClickAction => ({
  type: TRACK_IMAGE_IMPORT_CLICK,
  scribeId
});

export interface TrackImageLibraryClickAction {
  type: typeof TRACK_IMAGE_LIBRARY_CLICK;
  library: ImageCategory;
  scribeId: number;
  trigger?: TrackImageLibraryClickActionTrigger;
}
export type TrackImageLibraryClickActionTrigger = 'Category Index' | 'Category Carousel';
export const trackImageLibraryClick = (
  library: ImageCategory,
  scribeId: number,
  trigger?: TrackImageLibraryClickActionTrigger
): TrackImageLibraryClickAction => ({
  type: TRACK_IMAGE_LIBRARY_CLICK,
  library,
  scribeId,
  trigger
});

export interface TrackImageProviderChangeAction {
  type: typeof TRACK_IMAGE_PROVIDER_CHANGE;
  provider: UploadProvider;
  libraryState: 'search' | 'category';
}

export const trackImageProviderChange = (
  provider: UploadProvider,
  libraryState: 'search' | 'category'
): TrackImageProviderChangeAction => ({
  type: TRACK_IMAGE_PROVIDER_CHANGE,
  provider,
  libraryState
});

export interface TrackImageSearchAction {
  type: typeof TRACK_IMAGE_SEARCH;
  query: string;
  scribeId: number;
  tab: 'No Tab' | 'VideoScribe' | 'Noun Project';
}

export const trackImageSearch = (
  query: string,
  scribeId: number,
  tab: 'No Tab' | 'VideoScribe' | 'Noun Project'
): TrackImageSearchAction => ({
  type: TRACK_IMAGE_SEARCH,
  query,
  scribeId,
  tab
});

export interface TrackCursorSettingsOpenAction {
  type: typeof TRACK_CURSOR_SETTINGS_OPEN;
  scribeId: number;
}

export const trackCursorSettingsOpen = (scribeId: number): TrackCursorSettingsOpenAction => ({
  type: TRACK_CURSOR_SETTINGS_OPEN,
  scribeId
});

export interface TrackOpenDownloadAction {
  type: typeof TRACK_OPEN_DOWNLOAD;
  scribeId: number;
}

export const trackOpenDownload = (scribeId: number): TrackOpenDownloadAction => ({
  type: TRACK_OPEN_DOWNLOAD,
  scribeId
});

export interface TrackUpdateScribeBackgroundSettingsAction {
  type: typeof TRACK_UPDATE_SCRIBE_BACKGROUND_SETTINGS;
  scribeId: number;
  colorType?: ColorType;
}

export const trackUpdateScribeBackgroundSettings = (
  scribeId: number,
  colorType: ColorType
): TrackUpdateScribeBackgroundSettingsAction => ({
  type: TRACK_UPDATE_SCRIBE_BACKGROUND_SETTINGS,
  scribeId,
  colorType
});

export interface TrackAudioImportClickAction {
  type: typeof TRACK_AUDIO_IMPORT_CLICK;
  scribeId: number;
  audioType: AudioType;
}

export const trackAudioImportClick = (scribeId: number, audioType: AudioType): TrackAudioImportClickAction => ({
  type: TRACK_AUDIO_IMPORT_CLICK,
  scribeId,
  audioType
});

export type PlayButtonAction = 'fromStart' | 'fromElement' | 'fromScene';

export interface TrackPlayScribeAction {
  type: typeof TRACK_PLAY_SCRIBE;
  scribeId: number;
  button: PlayButtonAction;
  sceneCount: number;
  eventTrigger: string;
}

export const trackPlayScribe = (
  scribeId: number,
  button: PlayButtonAction,
  sceneCount: number,
  eventTrigger: string
): TrackPlayScribeAction => ({
  type: TRACK_PLAY_SCRIBE,
  scribeId,
  button,
  sceneCount,
  eventTrigger
});

export interface TrackOpenScribeAction {
  type: typeof TRACK_OPEN_SCRIBE;
  scribeId: number;
}

export const trackOpenScribe = (scribeId: number): TrackOpenScribeAction => ({
  type: TRACK_OPEN_SCRIBE,
  scribeId
});

export interface TrackFreeTransformSelectedAction {
  type: typeof TRACK_FREE_TRANSFORM_SELECTED;
  selected: boolean;
  elementType: ElementType;
  scribeId: number;
}

export const trackFreeTransformSelected = (
  selected: boolean,
  elementType: ElementType,
  scribeId: number
): TrackFreeTransformSelectedAction => ({
  type: TRACK_FREE_TRANSFORM_SELECTED,
  selected,
  elementType,
  scribeId
});

export interface TrackFlipElementAction {
  type: typeof TRACK_FLIP_ELEMENT;
  direction: DirectionType;
  elementType: ElementType;
  scribeId: number;
}

export const trackFlipElement = (
  direction: DirectionType,
  elementType: ElementType,
  scribeId: number
): TrackFlipElementAction => ({
  type: TRACK_FLIP_ELEMENT,
  direction,
  elementType,
  scribeId
});

export interface TrackSelectFontAction {
  type: typeof TRACK_SELECT_FONT;
  fontName: string;
  scribeId: number;
}

export const trackSelectFont = (fontName: string, scribeId: number): TrackSelectFontAction => ({
  type: TRACK_SELECT_FONT,
  fontName,
  scribeId
});

export interface TrackAlignTextAction {
  type: typeof TRACK_ALIGN_TEXT;
  alignment: TextAlignValue;
  scribeId: number;
}

export const trackAlignText = (alignment: TextAlignValue, scribeId: number): TrackAlignTextAction => ({
  type: TRACK_ALIGN_TEXT,
  alignment,
  scribeId
});

export interface TrackBoldTextAction {
  type: typeof TRACK_BOLD_TEXT;
  selected: boolean;
  scribeId: number;
  eventTrigger?: string;
}

export const trackBoldText = (selected: boolean, scribeId: number, eventTrigger?: string): TrackBoldTextAction => ({
  type: TRACK_BOLD_TEXT,
  selected,
  scribeId,
  eventTrigger
});

export interface TrackItalicTextAction {
  type: typeof TRACK_ITALIC_TEXT;
  selected: boolean;
  scribeId: number;
  eventTrigger?: string;
}

export const trackItalicText = (selected: boolean, scribeId: number, eventTrigger?: string): TrackItalicTextAction => ({
  type: TRACK_ITALIC_TEXT,
  selected,
  scribeId,
  eventTrigger
});

export interface TrackChangeElementColorAction {
  type: typeof TRACK_CHANGE_ELEMENT_COLOR;
  elementType: ElementType;
  colorType: ColorType;
  gradientDirection?: DirectionType;
  colorTab: ColorTab;
  scribeId: number;
  colorLayer?: ColorLayer;
  imageName?: string;
}

export type TrackChangeElementColorProperties = Omit<TrackChangeElementColorAction, 'type'>;

export const trackChangeElementColor = ({
  elementType,
  colorType,
  gradientDirection,
  colorTab,
  scribeId,
  colorLayer,
  imageName
}: TrackChangeElementColorProperties): TrackChangeElementColorAction => ({
  type: TRACK_CHANGE_ELEMENT_COLOR,
  elementType,
  colorType,
  gradientDirection,
  colorTab,
  scribeId,
  colorLayer,
  imageName
});

export const trackDownloadProgressHidden = () => ({
  type: TRACK_DOWNLOAD_PROGRESS_HIDDEN
});

export interface TrackFullScreenEnteredAction {
  type: typeof TRACK_FULLSCREEN_ENTERED;
  scribeId: number;
  eventTrigger: string;
  previewLocation: string;
  previewType: string;
}

export const trackFullscreenEntered = ({
  scribeId,
  eventTrigger,
  previewLocation,
  previewType
}: Omit<TrackFullScreenEnteredAction, 'type'>): TrackFullScreenEnteredAction => ({
  type: TRACK_FULLSCREEN_ENTERED,
  scribeId,
  eventTrigger,
  previewLocation,
  previewType
});
export interface TrackSelectAllAction {
  type: typeof TRACK_SELECT_ALL;
  scribeId: number;
  eventTrigger: string;
  hasScenesSelected: boolean;
}

export const trackSelectAll = ({
  scribeId,
  eventTrigger,
  hasScenesSelected
}: Omit<TrackSelectAllAction, 'type'>): TrackSelectAllAction => ({
  type: TRACK_SELECT_ALL,
  scribeId,
  eventTrigger,
  hasScenesSelected
});

interface TrackShareProjectAction {
  type: typeof TRACK_SHARE_PROJECT;
  scribeId: number;
  trigger: string;
}
export const trackShareProject = ({
  scribeId,
  trigger
}: Omit<TrackShareProjectAction, 'type'>): TrackShareProjectAction => ({
  type: TRACK_SHARE_PROJECT,
  scribeId,
  trigger
});

export interface TrackPreviewLinkCopyAction {
  type: typeof TRACK_PREVIEW_LINK_COPY;
  scribeId: number;
}
export const trackPreviewLinkCopy = ({
  scribeId
}: Omit<TrackPreviewLinkCopyAction, 'type'>): TrackPreviewLinkCopyAction => ({
  type: TRACK_PREVIEW_LINK_COPY,
  scribeId
});

export interface TrackImageReplaceModalAction {
  type: typeof TRACK_IMAGE_REPLACE_MODAL;
  scribeId: number;
  eventTrigger: string;
}

export const trackImageReplaceModal = ({
  scribeId,
  eventTrigger
}: {
  scribeId: number;
  eventTrigger: string;
}): TrackImageReplaceModalAction => ({
  type: TRACK_IMAGE_REPLACE_MODAL,
  scribeId,
  eventTrigger
});

export interface TrackImageReplaceAction {
  type: typeof TRACK_IMAGE_REPLACE;
  scribeId: number;
  filename: string;
  provider: string;
  imageType: string;
  selectedLibrary?: string;
  recolorAvailable?: boolean;
  imageSize?: string | number;
  imageId?: string | number;
  temporaryAssetId?: string | number;
}

export const trackImageReplace = ({
  scribeId,
  filename,
  imageId,
  provider,
  imageType,
  selectedLibrary,
  recolorAvailable,
  imageSize,
  temporaryAssetId
}: Omit<TrackImageReplaceAction, 'type'>): TrackImageReplaceAction => ({
  type: TRACK_IMAGE_REPLACE,
  scribeId,
  filename,
  imageId,
  provider,
  imageType,
  selectedLibrary,
  recolorAvailable,
  imageSize,
  temporaryAssetId
});
export interface TrackSetActiveElementsVisibilityAction {
  type: typeof TRACK_SET_ACTIVE_ELEMENTS_VISIBILITY;
  scribeId: number;
  elementIds: Array<string>;
  eventTrigger: HideEventTrigger;
  eventName: string;
}

export const trackSetActiveElementsVisibility = ({
  scribeId,
  elementIds,
  eventTrigger,
  eventName
}: Omit<TrackSetActiveElementsVisibilityAction, 'type'>): TrackSetActiveElementsVisibilityAction => ({
  type: TRACK_SET_ACTIVE_ELEMENTS_VISIBILITY,
  scribeId,
  elementIds,
  eventTrigger,
  eventName
});

export const trackSearchByProjectTitle = (): { type: typeof TRACK_SEARCH_BY_PROJECT_TITLE } => ({
  type: TRACK_SEARCH_BY_PROJECT_TITLE
});

export interface TrackCreateNewProjectAction {
  type: typeof TRACK_CREATE_NEW_PROJECT;
  eventTrigger: string;
}

export const trackCreateNewProject = (eventTrigger: string): TrackCreateNewProjectAction => ({
  type: TRACK_CREATE_NEW_PROJECT,
  eventTrigger
});

export const trackTimelineZoomExpanded = () => ({
  type: TRACK_TIMELINE_ZOOM_EXPANDED
});

export const trackTimelineScrollToBeginning = () => ({
  type: TRACK_TIMELINE_SCROLL_TO_BEGINNING
});

export const trackTimelineScrollToEnd = () => ({
  type: TRACK_TIMELINE_SCROLL_TO_END
});

export const trackCameraWarningModal = (scribeId: number) => ({
  type: TRACK_CAMERA_WARNING_MODAL,
  scribeId: scribeId
});

type OpenInitialActionInModalEventTrigger = 'LHP Text Button' | 'Your Files Button';
export interface TrackOpenRecordAudioModalAction {
  type: typeof TRACK_OPEN_RECORD_AUDIO_MODAL;
  eventTrigger: OpenInitialActionInModalEventTrigger;
}
export const trackOpenRecordAudioModal = (
  eventTrigger: OpenInitialActionInModalEventTrigger
): TrackOpenRecordAudioModalAction => ({
  type: TRACK_OPEN_RECORD_AUDIO_MODAL,
  eventTrigger
});

export interface TrackOpenUploadAudioModalAction {
  type: typeof TRACK_OPEN_UPLOAD_AUDIO_MODAL;
  eventTrigger: OpenInitialActionInModalEventTrigger;
}
export const trackOpenUploadAudioModal = (
  eventTrigger: OpenInitialActionInModalEventTrigger
): TrackOpenUploadAudioModalAction => ({
  type: TRACK_OPEN_UPLOAD_AUDIO_MODAL,
  eventTrigger
});

export type PreviewAudioEventTrigger = 'Audio Library' | 'Your Files' | 'Right Hand Panel';
export interface TrackPreviewAudioAction {
  type: typeof TRACK_PREVIEW_AUDIO;
  eventTrigger: PreviewAudioEventTrigger;
  trackName?: string;
}
export const trackPreviewAudio = (eventTrigger: PreviewAudioEventTrigger, trackName?: string) => ({
  type: TRACK_PREVIEW_AUDIO,
  eventTrigger,
  trackName
});

type AudioSearchTrigger = 'Your Files' | 'Audio Library';
type SearchEventProperties = {
  term: string;
};
export type AudioLibrarySearchEventProperties = SearchEventProperties & {
  genres?: string[];
  tempos?: string[];
  scribeId?: string;
  numberOfResults?: number;
};
export type TrackSearchAudioFilesAction = {
  type: typeof TRACK_SEARCH_AUDIO_FILES;
  eventTrigger: AudioSearchTrigger;
} & AudioLibrarySearchEventProperties;

export const trackSearchAudioFiles = (
  eventTrigger: AudioSearchTrigger,
  eventProperties: AudioLibrarySearchEventProperties
): TrackSearchAudioFilesAction => ({
  type: TRACK_SEARCH_AUDIO_FILES,
  eventTrigger,
  ...eventProperties
});

export type AudioLibarySelectFilterEventProperties = {
  scribeId: string;
  genres?: string[];
  tempos?: string[];
};

export type TrackSelectFilterAudioFilesAction = {
  type: typeof TRACK_SELECT_FILTER_AUDIO_FILES;
} & AudioLibarySelectFilterEventProperties;

export const trackSelectFilterAudioFiles = (
  eventProperties: AudioLibarySelectFilterEventProperties
): TrackSelectFilterAudioFilesAction => ({
  type: TRACK_SELECT_FILTER_AUDIO_FILES,
  ...eventProperties
});

export interface TrackFilterByCategoryAction {
  type: typeof TRACK_FILTER_TEMPLATES_BY_CATEGORY;
  categoryTitle?: string;
  eventTrigger?: string;
}

export const trackFilterTemplatesByCategory = (
  categoryTitle?: string,
  eventTrigger?: string
): TrackFilterByCategoryAction => ({
  type: TRACK_FILTER_TEMPLATES_BY_CATEGORY,
  categoryTitle,
  eventTrigger
});

export enum UpgradeSubscriptionClickedEventTrigger {
  UPLOAD_CUSTOM_FONT = 'Upload Custom Font',
  DOWNLOAD_SUCCESS_BANNER = 'Download Success Banner',
  CANVAS_NAV = 'Canvas Nav',
  HOME_BANNER = 'Home Banner',
  NOUN_PROJECT = 'Noun Project',
  DOWNLOAD_LIMIT = 'Download Limit',
  OVER_PROJECT_LIMIT = 'Over Project Limit',
  OVER_DURATION_LIMIT = 'Download Duration',
  EDITOR_BANNER = 'Editor Banner',
  ELEMENT_LIMIT = 'Element Limit',
  HOME_NAV = 'Home Nav',
  CREATE_NEW_SCRIBE = 'Create New Scribe',
  PREMIUM_TEMPLATE = 'Premium Template',
  PREVIEW_VIEW = 'Preview View',
  EDIT_TEMPLATE = 'Edit Template',
  SCRIBE_TILE_MENU = 'Scribe Tile Menu',
  UPGRADE_WARNING_MODAL = 'Upgrade Warning Modal',
  TEMPLATE_PREVIEW_MODAL = 'Template Preview Modal',
  CREATE_PREVIEW_LINK = 'Create Preview Link',
  PREMIUM_BOUNDING_BOX_INDICATOR = 'Premium bounding box',
  PREMIUM_THUMBNAIL_INDICATOR = 'Premium icon in library',
  IMAGE_GENERATION_LIMIT = 'Image Generation Limit',
  VOICE_GENERATION_LIMIT = 'Voice Generation Limit',
  SHARE_YOUR_VIDEO_MODAL = 'Share your video modal'
}

export interface TrackUpgradeSubscriptionButtonClickedAction {
  type: typeof TRACK_UPGRADE_SUBSCRIPTION_BUTTON_CLICKED;
  tier: AccountType;
  term: AccountTerm;
  eventTrigger: UpgradeSubscriptionClickedEventTrigger;
  extraEventProps?: UpgradeButtonClickExtraEventProps;
}

export const trackUpgradeSubscriptionButtonClicked = (
  tier: AccountType,
  term: AccountTerm,
  eventTrigger: UpgradeSubscriptionClickedEventTrigger,
  extraEventProps?: UpgradeButtonClickExtraEventProps
): TrackUpgradeSubscriptionButtonClickedAction => ({
  type: TRACK_UPGRADE_SUBSCRIPTION_BUTTON_CLICKED,
  tier,
  term,
  eventTrigger,
  extraEventProps
});

export interface TrackPurchaseSubscriptionButtonClickedAction {
  type: typeof TRACK_PURCHASE_SUBSCRIPTION_BUTTON_CLICKED;
  tier: AccountType;
  term: AccountTerm;
  eventTrigger: UpgradeSubscriptionClickedEventTrigger;
  extraEventProps?: UpgradeButtonClickExtraEventProps;
}

export const trackPurchaseSubscriptionButtonClicked = (
  tier: AccountType,
  term: AccountTerm,
  eventTrigger: UpgradeSubscriptionClickedEventTrigger,
  extraEventProps?: UpgradeButtonClickExtraEventProps
): TrackPurchaseSubscriptionButtonClickedAction => ({
  type: TRACK_PURCHASE_SUBSCRIPTION_BUTTON_CLICKED,
  tier,
  term,
  eventTrigger,
  extraEventProps
});

export interface TrackNoAccessFeatureModalDisplayedAction {
  type: typeof TRACK_NO_ACCESS_FEATURE_MODAL_DISPLAYED;
  scribeId: number;
  triggerSource: UpgradeSubscriptionClickedEventTrigger;
}

export const trackNoAccessFeatureModalDisplayed = (
  scribeId: number,
  triggerSource: UpgradeSubscriptionClickedEventTrigger
): TrackNoAccessFeatureModalDisplayedAction => ({
  type: TRACK_NO_ACCESS_FEATURE_MODAL_DISPLAYED,
  scribeId,
  triggerSource
});

export interface TrackSplitAudioTrackAction {
  type: typeof TRACK_SPLIT_AUDIO_TRACK;
  scribeId: number;
  audioType: AudioType;
}

export const trackSplitAudioTrack = (scribeId: number, audioType: AudioType): TrackSplitAudioTrackAction => ({
  type: TRACK_SPLIT_AUDIO_TRACK,
  scribeId,
  audioType
});

export interface TrackAudioSplitModeCancelAction {
  type: typeof TRACK_AUDIO_SPLIT_MODE_CANCEL;
  audioType: AudioType;
  scribeId: number;
}

export const trackAudioSplitModeCancel = (scribeId: number, audioType: AudioType): TrackAudioSplitModeCancelAction => ({
  type: TRACK_AUDIO_SPLIT_MODE_CANCEL,
  audioType,
  scribeId
});
