import './CategoryCarousel.css';

import React from 'react';
import { ImageCategory } from 'js/types';
import { useDispatch } from 'react-redux';
import { useProject } from 'js/shared/hooks/useProject';
import { trackImageLibraryClick } from 'js/actionCreators/trackingActions';

import QuickSearchCarousel from '../QuickSearchCarousel';
import { useLibraries } from '../../hooks/useLibraries';
import QuickCarouselTag from '../QuickCarouselTag';

const CategoryCarousel = ({
  categoryId,
  onCategoryClick
}: {
  categoryId: string;
  onCategoryClick: (library: ImageCategory) => void;
}) => {
  const [loading, libraries] = useLibraries();
  const dispatch = useDispatch();
  const project = useProject();

  if (loading) {
    return null;
  }

  return (
    <div className="CategoryCarousel">
      <QuickSearchCarousel>
        {libraries.map(library => {
          const handleClick = () => {
            if (project) {
              onCategoryClick(library);
              dispatch(trackImageLibraryClick(library, project.id, 'Category Carousel'));
            }
          };

          return (
            <QuickCarouselTag key={library.id} active={categoryId === library.id} onClick={handleClick}>
              {library.title}
            </QuickCarouselTag>
          );
        })}
      </QuickSearchCarousel>
    </div>
  );
};

export default CategoryCarousel;
