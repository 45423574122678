import { RouterState } from 'connected-react-router';
import * as PIXI from 'pixi.js';
import { UpgradeSubscriptionClickedEventTrigger } from 'js/actionCreators/trackingActions';
import { NEXT_ACTION_TYPES } from 'js/shared/components/Callout/consts';
import { IMAGE_GENERATION, SCRIPT_GENERATION, VOICE_GENERATION } from 'js/config/featureKeys';
import { IMAGE_GEN_STYLES, LIMITS } from 'js/config/consts';

import {
  BOUNCE_IN_KEY,
  BOUNCE_LOOP_KEY,
  DEFAULT_DRAG_CURSOR_ID,
  DEFAULT_ERASE_CURSOR_ID,
  DEFAULT_HAND_CURSOR_ID,
  DEFAULT_PEN_CURSOR_ID,
  DISAPPEAR_KEY,
  DRAG_IN_KEY,
  DRAW_KEY,
  EMPHASIS_ANIMATION_DOCUMENT_KEY,
  ENTRANCE_ANIMATION_DOCUMENT_KEY,
  EXIT_ANIMATION_DOCUMENT_KEY,
  EXIT_ANIMATION_ERASE_KEY,
  FADE_IN_KEY,
  FADE_OUT_KEY,
  HAND_DRAW_KEY,
  MOVE_IN_KEY,
  MOVE_OUT_KEY,
  NO_ANIMATION_KEY,
  PEN_DRAW_KEY,
  PULSE_LOOP_KEY,
  SCENE_TRANSITION_KEY_BOUNCE,
  SCENE_TRANSITION_KEY_DRAG_ON,
  SCENE_TRANSITION_KEY_ERASE,
  SCENE_TRANSITION_KEY_FADE,
  SCENE_TRANSITION_KEY_NONE,
  SCENE_TRANSITION_KEY_SLIDE,
  SCENE_TRANSITION_NO_CURSOR_ID,
  SHAKE_LOOP_KEY,
  SLINGSHOT_KEY,
  SPIN_LOOP_KEY,
  ON_ELEMENT_TRIGGER,
  CAMERA_EASING_EASEOUT,
  CAMERA_EASING_LINEAR,
  CAMERA_EASING_NONE
} from './config/consts';
import CircleElement from './editor/EditorCanvas/CircleElement';
import EllipseElement from './editor/EditorCanvas/EllipseElement';
import SquareElement from './editor/EditorCanvas/SquareElement';
import TriangleElement from './editor/EditorCanvas/TriangleElement';
import ScribeImageElementModel from './models/ScribeImageElementModel';
import ScribeModel from './models/ScribeModel';
import ScribeShapeElementModel from './models/ScribeShapeElementModel';
import ScribeTextElementModel from './models/ScribeTextElementModel';
import VSRasterImage from './playback/lib/Playback/models/VSRasterImage';
import VSBounceTransition from './playback/lib/Playback/models/VSSceneTransitions/VSBounceTransition';
import VSDragOnTransition from './playback/lib/Playback/models/VSSceneTransitions/VSDragOnTransition';
import VSEraseTransition from './playback/lib/Playback/models/VSSceneTransitions/VSEraseTransition';
import VSFadeTransition from './playback/lib/Playback/models/VSSceneTransitions/VSFadeTransition';
import VSSlideTransition from './playback/lib/Playback/models/VSSceneTransitions/VSSlideTransition';
import VSShapeElement from './playback/lib/Playback/models/VSShapeElement';
import VSTextElement from './playback/lib/Playback/models/VSTextElement';
import VSVectorImage from './playback/lib/Playback/models/VSVectorImage';
import UserModel from './shared/helpers/app-services/UserModel';
import { DRAW_TYPE_ILLUSTRATE, DRAW_TYPE_REVEAL } from './config/animationOptions';
import SceneTransitionElementModel from './models/SceneTransitionElementModel';
import { ScribeAudioLayerModel } from './models/ScribeAudioLayerModel';
import { ProjectScriptsState } from './reducers/projectScriptsReducer';
import { TagOption } from './shared/components/Tags';
import SVGImageElement from './editor/EditorCanvas/SVGImageElement';
import ImageElement from './editor/EditorCanvas/ImageElement';
import TextElement from './editor/EditorCanvas/TextElement';
import { ImageSearchState } from './reducers/imageSearchReducer';

export type VSElementModel = VSImageShapeOrTextElementModel | ScribeCameraElement;

export type VSImageShapeOrTextElementModel =
  | ScribeImageElementModel
  | ScribeShapeElementModel
  | ScribeTextElementModel
  | SceneTransitionElementModel;

export type VSShapeOrTextElementModel = ScribeShapeElementModel | ScribeTextElementModel;

export interface NewElementProps {
  canvasSize: { height: number; width: number };
  width: number;
  height: number;
}

export interface ScribeElementModelProps {
  id: string;
  type: ElementType;
  width: number;
  height: number;
  x: number;
  y: number;
  scaleX: number;
  scaleY: number;
  angle: number;
  originX: string;
  originY: string;
  flipX: boolean;
  flipY: boolean;
  locked: boolean;
  unlockedRatio: boolean;
  hidden: boolean;
  outOfCamera: boolean;

  duration?: number;
  pauseTime?: number;
  customPauseTime?: number;
  animationTime?: number;
  emphasisAnimationTime?: number;
  exitAnimationTime?: number;
  emphasisAnimationLoops?: number;
  cursorId?: ScribeCursorId;
  entranceTween?: TweenAnimation;
  exitTween?: TweenAnimation;
  emphasisTween?: TweenAnimation;
  opacity?: number;

  exitAnimation?: AnimationConfiguration;
  entranceAnimation?: AnimationConfiguration;
  emphasisAnimation?: AnimationConfiguration;

  isPremium?: boolean;
}

export type ScribeImageElementModelProps = ScribeElementModelProps & {
  type: 'Image';
  useContentSvgViewbox?: boolean;
  _imageUrl?: string | null;
  viewboxAttributes?: ViewBoxAttributes;
  image?: ImageDetails;
  _imageLoaded?: boolean;
  _imageLoading?: boolean;
  _recolorDefaults?: RecolorDefaults;
  recolorAvailable?: boolean;
  containsEmbeddedImage?: boolean;
};
export type SceneTransitionElementModelProps = ScribeElementModelProps & {
  type: 'SceneTransition';
  sceneTransitionTime: number;
  sceneTransitionType: SceneTransitionKey;
};

export type ScribeShapeElementModelProps = ScribeElementModelProps & {
  type: 'Shape';
  shapeType: ShapeType;
  fill: string;
  backgroundType: BackgroundType;
  backgroundColorFrom: string;
  backgroundColorTo: string;
  backgroundGradientType: BackgroundGradientType;

  radius?: number;
  rx?: number;
  ry?: number;

  /**@deprecated */
  backgroundColourFrom?: string;
  /**@deprecated */
  backgroundColourTo?: string;
};

export type NewScribeShapeElementProps = NewElementProps & {
  shapeType: ShapeType;
};

export type TextElementCharBounds = {
  left: number;
  width: number;
  kernedWidth?: number;
  height?: number;
  deltaY?: number;
}[][];

export type ScribeTextElementModelProps = ScribeElementModelProps & {
  type: 'Text';
  font?: FontAttributes;
  text?: string;
  align?: TextAlignValue;
  fill?: string;
  color?: string;
  fontSize?: number;
  fontWeight?: TextFontWeight;
  fontStyle?: TextFontStyle;
  opacity?: number;
  styles?: TextElementCustomStyles;
  charBounds?: TextElementCharBounds;
  lineWidths?: number[];
};

type CustomisableElementStylesProps = {
  fill?: string;
  fontSize?: number;
  fontWeight?: TextFontWeight;
  fontStyle?: TextFontStyle;
  opacity?: number;
};

export type NewScribeTextElementProps = NewElementProps &
  CustomisableElementStylesProps & {
    font?: FontAttributes;
    text?: string;
    align?: TextAlignValue;
  };

export type CustomisableTextElementProps = {
  fill?: string;
};

export type TextElementCustomStyles = {
  [rowIndex: number]: {
    [columnIndex: number]: CustomisableTextElementProps;
  };
};

export type CanvasSize = 'portrait' | 'landscape' | 'square';

export type BackgroundType = 'solid' | 'gradient';

export type BackgroundGradientType = DirectionType;

export const SCRIBE_CURSOR_IDS = [
  SCENE_TRANSITION_NO_CURSOR_ID,
  DEFAULT_PEN_CURSOR_ID,
  DEFAULT_HAND_CURSOR_ID,
  DEFAULT_ERASE_CURSOR_ID,
  DEFAULT_DRAG_CURSOR_ID
] as const;

export type ScribeCursorId = (typeof SCRIBE_CURSOR_IDS)[number] | number;

export interface SceneTransitionConfig {
  rotate?: string;
  times?: number | string;
  direction?: string;
  cursorId?: ScribeCursorId;
}

export interface ScribeBackgroundSettings {
  backgroundColor: string;

  /** @deprecated  VSP2-3387 - This properties become optional as we are migrating the background and stripping out these properties*/
  backgroundColorTo?: string;
  /** @deprecated VSP2-3387 - This properties become optional as we are migrating the background and stripping out these properties*/
  backgroundColorFrom?: string;
  /** @deprecated VSP2-3387 - This properties become optional as we are migrating the background and stripping out these properties*/
  backgroundType?: string;
  /** @deprecated VSP2-3387 - This properties become optional as we are migrating the background and stripping out these properties*/
  backgroundGradientType?: BackgroundGradientType;
}

export interface ScribeSettings extends ScribeBackgroundSettings {
  pauseTime: number;
  animationTime: number;
  emphasisAnimationTime: number;
  exitAnimationTime: number;
  emphasisAnimationLoops: number;
  sceneTransitionType?: SceneTransitionKey;
  sceneTransitionTime?: number;
  sceneTransitionConfig?: SceneTransitionConfig;
  textStylingConfig: TextStylingConfig;
}

export interface ScribeSettingsProps {
  pauseTime?: number;
  animationTime?: number;
  emphasisAnimationTime?: number;
  exitAnimationTime?: number;
  emphasisAnimationLoops?: number;
  sceneTransitionConfig?: SceneTransitionConfig;
  sceneTransitionType?: SceneTransitionKey;
  sceneTransitionTime?: number;
  textStylingConfig?: TextStylingConfig;

  /** @deprecated  VSP2-3387 - This properties become optional as we are migrating the background and stripping out these properties*/
  backgroundColor?: string;
  /** @deprecated  VSP2-3387 - This properties become optional as we are migrating the background and stripping out these properties*/
  backgroundColorTo?: string;
  /** @deprecated  VSP2-3387 - This properties become optional as we are migrating the background and stripping out these properties*/
  backgroundColorFrom?: string;
  /** @deprecated  VSP2-3387 - This properties become optional as we are migrating the background and stripping out these properties*/
  backgroundType?: string;
  /** @deprecated  VSP2-3387 - This properties become optional as we are migrating the background and stripping out these properties*/
  backgroundGradientType?: string;
  /** @deprecated */
  backgroundColourFrom?: string;
  /** @deprecated */
  backgroundColourTo?: string;
  /** @deprecated */
  backgroundColour?: string;
}

export type ScribeSource = {
  type: 'template' | 'blank';
  meta?: { templateId: string; title: string };
};

export interface ScribeScene {
  id: string;
  active: boolean;
  elementIds: Array<string>;
  audioLayerIds?: Array<string>;
  settings: ScribeSettings;
  thumbnailImage?: string;
}

export type AudioClipData = {
  assetId: number;
  type: AudioType | AdvancedAudioType;
  source: AudioSource;
  volume: number;
  duration?: number;
  instanceDuration?: number;
  fadeOutDurationSeconds: number;
  filename: string;
  /**  @deprecated migrate to all lowercase property*/
  fileName?: string;

  startTime?: number;
  startOffset?: number;
  id?: string;
};

export type AudioClip = AudioClipData & {
  id: string;
};

export interface EditableAudioClipMetadata {
  audioType?: AdvancedAudioType;
  filename: string;
  description?: string;
}

export interface AudioClipMetaData extends EditableAudioClipMetadata {
  assetId: number;
  filename: string;
  audioSourceType?: VSCAssetAudioSourceName;
  audioDurationSeconds?: number;
  description?: string;
  dateUploaded: Date;
}

export type VSElements =
  | ScribeImageElementModelProps
  | ScribeShapeElementModelProps
  | ScribeTextElementModelProps
  | ScribeCameraElement;

export type ScribeAudioLayerModelProps = {
  id: string;
  audioClipIds: Array<string>;
};

export interface ScribeModelProps {
  id?: number;
  title: string;
  settings?: ScribeSettings;
  canvasSize?: CanvasSize;
  thumbnailImage?: string;
  createdOn?: Date;
  updatedOn?: Date;
  cursor?: ScribeCursorId;
  source?: ScribeSource;
  scenes?: Array<ScribeScene>;
  audioClips?: Array<AudioClipData>;
  audio?: AudioClipData;
  audioLayers?: Array<ScribeAudioLayerModelProps>;
  rawElements?: Array<VSElements>;
  modifiedDate?: string | number;
  publicSharingId?: string;
  pinAllCameras?: boolean;
  projectAudioLayerIds?: Array<string>;
}

export type ElementType = 'Text' | 'Shape' | 'Image' | 'Camera' | 'SceneTransition';

export type Tween = {
  [key: string]: string | number | undefined;
  alpha?: number;
  position?: string;
  ease?: string;
  scaleX?: number;
  scaleY?: number;
  anchor?: string;
  percentage_of_total?: number;
  rotation?: number;
  x?: string;
  y?: string;
  direction?: string;
};

export interface TweenAnimation {
  id: string;
  tweens?: Array<Tween>;
  tweens_in?: Array<Tween>;
  tweens_out?: Array<Tween>;
}

export type TweenInOrOut = Tween & {
  percentage_of_total: number;
};

export type DrawType = typeof DRAW_TYPE_REVEAL | typeof DRAW_TYPE_ILLUSTRATE;
export interface AnimationConfigurationOptions {
  [key: string]: unknown;
  cursorId?: ScribeCursorId;
  drawType?: DrawType;
}

export interface AnimationConfiguration {
  id: AllAnimationTypeKeys;
  config?: AnimationConfigurationOptions;
}

export type BaseAnimationTypeKeys = typeof NO_ANIMATION_KEY;

export type EntranceAnimationTypeKeys =
  | BaseAnimationTypeKeys
  | typeof DRAW_KEY
  | typeof PEN_DRAW_KEY
  | typeof HAND_DRAW_KEY
  | typeof FADE_IN_KEY
  | typeof MOVE_IN_KEY
  | typeof BOUNCE_IN_KEY
  | typeof DRAG_IN_KEY;

export type EmphasisAnimationTypeKeys =
  | BaseAnimationTypeKeys
  | typeof BOUNCE_LOOP_KEY
  | typeof SPIN_LOOP_KEY
  | typeof PULSE_LOOP_KEY
  | typeof SHAKE_LOOP_KEY;

export type ExitAnimationTypeKeys =
  | BaseAnimationTypeKeys
  | typeof FADE_OUT_KEY
  | typeof MOVE_OUT_KEY
  | typeof SLINGSHOT_KEY
  | typeof DISAPPEAR_KEY
  | typeof EXIT_ANIMATION_ERASE_KEY;

export type AllAnimationTypeKeys = EntranceAnimationTypeKeys | EmphasisAnimationTypeKeys | ExitAnimationTypeKeys;

export type CameraAnimationKeys =
  | typeof CAMERA_EASING_EASEOUT
  | typeof CAMERA_EASING_LINEAR
  | typeof CAMERA_EASING_NONE;

export interface ShakeTweenAnimation {
  id: typeof SHAKE_LOOP_KEY;
  tweens_in: Array<TweenInOrOut>;
  tweens_out: Array<TweenInOrOut>;
}

export interface ImageDetails {
  assetId: number;
  provider: string;
  contentType: string;
  recolor?: RecolorDefaults;
  filename?: string;
}

export interface SVGViewBoxAttributes {
  viewBox: string;
  width: number;
  height: number;
}

export interface ViewBoxAttributes {
  contents?: SVGViewBoxAttributes;
  original?: SVGViewBoxAttributes;
}
export interface FontAttributes {
  label: string;
  value: string;
  assetId?: number;
  custom?: boolean;
}

export type TextAlignValue = 'left' | 'center' | 'right';

export type TextFontWeight = 'normal' | 'bold';

export type TextFontStyle = 'normal' | 'italic';

export type ShapeType = 'square' | 'triangle' | 'circle' | 'ellipse';

export type ScribeClipboard =
  | {
      type: 'element';
      metadata: {
        sceneId: string;
        wasCut: boolean;
      };
      data: Array<VSElementModel>;
    }
  | SceneClipboardPayload;

export type SceneClipboardPayload = {
  type: 'scene';
  metadata: {
    elements: Array<ElementClone>;
    canvasSize: CanvasSize;
    audioClips?: AudioClip[];
    audioLayers?: ScribeAudioLayerModel[];
  };
  data: Array<SceneClone>;
};

export interface ElementClone extends Omit<VSElementModel, 'id'> {
  tempId?: string;
  id?: string;
}

export interface SceneClone extends Omit<ScribeScene, 'id'> {
  elementIds: Array<string>;
  id?: string;
}

export type SortOrder = 'alphaAscend' | 'alphaDescend' | 'updatedDate' | 'createdDate' | 'default';

export interface Cursor {
  id: number;
  name: string;
  version: number;
  sortOrder: number;
  source: string;
  author: string;
  metadata: string;
  group: string;
  resources: {
    hand1: {
      url: string;
      type: 'hand1';
    };
    hand2: {
      url: string;
      type: 'hand2';
    };
    thumbnail: {
      url: string;
      type: 'thumbnail';
    };
  };
}

export interface CursorOption {
  id: ScribeCursorId;
  group: string;
  name: string;
  resources?: {
    thumbnail: {
      url: string;
    };
  };
}

export interface VSCAssetData {
  projectAssetId: number;
  createdDate: string;
  lastUsed?: string;
  contentType: string;
  filename: string | null;
  id: number;
  description?: string;
  sourceName?: VSCAssetImageSourceName | VSCAssetAudioSourceName;
  isPremium?: boolean;
}

export interface VSCAssetImageData extends VSCAssetData {
  url: string;
}

export type VSCFontAssetData = {
  assetUrl: string;
} & Omit<VSCAssetData, 'id'>;

export enum VSCAssetAudioSourceName {
  USER_UPLOAD = 'user-upload',
  USER_RECORDING = 'user-recording',
  SPARKOL_AUDIO = 'sparkol-audio',
  SPARKOL_AI_GENERATED = 'sparkol-ai-generated'
}

export enum VSCAssetImageSourceName {
  NOUN_PROJECT = 'noun-project',
  SPARKOL_IMAGE = 'sparkol-image',
  AI = 'sparkol-ai-generated',
  USER_UPLOAD = 'user-upload'
}

export interface VSCAssetAudioData extends Omit<VSCAssetData, 'id'> {
  audioDurationSeconds?: number;
  audioType?: AdvancedAudioType;
  sourceName?: VSCAssetAudioSourceName;
  sourceIdentifier?: string;
}

export interface FontConfirmation {
  invalidFiles: Array<string>;
  duplicateFiles: Array<string>;
  filesUploaded: Array<string>;
}

export interface FontsState {
  userFonts: Array<VSCFontAssetData>;
  showUploadCustomFontsModal: boolean;
  uploadStage: number;
  uploadStatus: {
    max: number;
    complete: number;
    message: string;
  };
  confirmation: FontConfirmation;
  error?: Error;
  showDeleteCustomFontsModal: boolean;
  selectedFont: SelectedFontData | null;
  isUpdatingBitmapFonts: boolean;
}

export interface SelectedFontData {
  label: string;
  value: string;
  assetId: number;
  custom: boolean;
}

export type CursorsState = {
  handErase: Array<Cursor>;
  handDrag: Array<Cursor>;
  penDraw: Array<Cursor>;
  handDraw: Array<Cursor>;
};

export type DownloadState = {
  renderInProgress: boolean;
  downloadUrl: string | null;
  error: Error | null;
  downloadingInProgress: boolean;
  formatRequested: string | null;
  segmentsRequested: number;
  currentDownloadTrackingProperties: DownloadTrackingProperties | null;
  renderStatus: {
    max: number;
    complete: number;
    message: string;
    started?: number;
  };
};

export type AudioTabType = 'your-files' | 'audio-library';
export type AudioActionType = 'record' | 'upload' | 'replace' | undefined;
export interface AudioState {
  userAudioLoading: boolean;
  userAudioAssets: Array<VSCAssetAudioData>;
  audioModalTab: null | AudioTabType;
  audioModalInitialAction: null | AudioActionType;
  deleteAudioAssetModalOpen: boolean;
  deleteAudioAssetId: number | null;
  selectedAudioClip: AudioClip | null;
}

export interface AudioUploadState {
  uploading: boolean;
  uploadingFilename: string;
  savingMetadata: boolean;
  lastUploadId?: number;
  lastUploadSource?: AudioSource;
  incomingAudioAsset?: VSCAssetAudioData;
  uploadAbortController?: AbortController;
}

export type ExistingScribeModel = ScribeModel & {
  id: number;
  createdOn: Date;
  updatedOn: Date;
  selectedElementIds?: string[];
  selectedAudioClipId?: string;
  nextSelectedElementIds?: string[];
  nextSelectedAudioClipId?: string;
};

export interface TemporaryDuplicatedScribe {
  title: string;
  createdOn: Date;
  updatedOn: Date;
  loadingId: string;
  status: string;
}

export interface HomepageProject {
  id: number;
  title: string;
  createdOn: Date;
  updatedOn: Date;
  canvasSize: CanvasSize;
  thumbnailImage?: string;
  viewOnly?: boolean;
}

export type CustomisableTextStyleProps = {
  fill?: string;
};

export type CustomTextStyleType = {
  [K in keyof CustomisableTextStyleProps]: CustomisableTextStyleProps[K];
};

export type ScribeState = {
  allScribes: Array<ExistingScribeModel>;
  selectedSceneIds: Array<string>;
  selectedAudioClip: AudioClip | null | undefined;
  selectedTextColor: string | null | undefined;
  activeElements: Array<string>;
  activeScribePast: Array<ExistingScribeModel>;
  activeScribeFuture: Array<ExistingScribeModel>;
  loadingAllScribes: boolean;
  loadingScribes: Array<ExistingScribeModel | TemporaryDuplicatedScribe>;
  scribesSortOrder: SortOrder;
  loadingScribeForEditor: boolean;
  lastSelectedScene: string | null;
  duplicatingScribe: boolean;
  firstLoad: boolean;
  lastSelectedElement: Array<string> | string | null;
  canvasSizeFilter: string;
  projectSearchTerm: string;
  homepageProjects: Array<HomepageProject>;
  isTextboxEditing: boolean;
  customTextStyleToBeSet: CustomTextStyleType | null;
};

export type CurrentUser =
  | {
      id?: string;
      gracePeriodHasEnded: boolean;
      accountType: AccountType;
      term?: string;
    }
  | UserModel;

export type AuthState = {
  currentUser: CurrentUser;
  isAuthenticating: boolean;
  accountManagementRedirect: boolean;
  noOnlineStorage: boolean;
  error?: string;
};
export interface ImageCrop {
  scribeId: number;
  elementId: string;
  sceneId: string;
  imageUrl: string;
  transforms: {
    scaleX: number;
    scaleY: number;
    flipX: boolean;
    flipY: boolean;
  };
  imageType: string;
  filename?: string;
  crop?: VSCrop;
}

export type CropShapeTypes = 'RECTANGLE' | 'SQUARE' | 'CIRCLE' | 'ELLIPSE';

export interface VSCrop {
  blob: Blob;
  options: {
    left: number;
    top: number;
    width: number;
    height: number;
    imageScaleFactor: number;
  };
}

export interface CroppingState {
  imageCrop: ImageCrop | null;
  imageCropShape?: CropShapeTypes;
  showCropWarning: boolean;
}

export type PendingSave = {
  isSaving: boolean;
  pendingSave: null | ExistingScribeModel;
};

export type PendingSaveQueue = {
  [projectId: number]: PendingSave;
};

export interface AutoSaveState {
  autoSaveError: Error | null;
  autoSaveStatus: string;
  pendingSaveQueue: PendingSaveQueue;
}

export type HomeViewOptions = 'templates' | 'my-projects';

export interface IsSavingAudio {
  voiceover?: boolean;
  background?: boolean;
}

export type ProjectsViewMode = 'grid' | 'list';

export enum LeftHandPanel {
  AI_GENERATION = 'AI_IMAGE_GENERATION',
  SCENES = 'SCENES',
  AUDIO = 'AUDIO',
  SCRIPTS = 'SCRIPTS',
  IMAGES = 'IMAGES'
}

export interface UIState {
  currentEditAnimationTab: 'animation' | 'edit' | null;
  editPanelMode: string | null;
  showCallout: Callout | null;
  editorMode: string;
  isLoadingPlayback: boolean;
  uiIsLoading: boolean;
  currentHomeView: HomeViewOptions;
  isSavingAudio: IsSavingAudio;
  isAudioPanelBusy: boolean;
  applicationFontsLoaded: boolean;
  editorLoadingMessage: string;
  previewLinkIsLoading: boolean;
  projectsViewMode: ProjectsViewMode;
  newTimeline: boolean;
  firstArrowPressed: boolean;
  showElementsPanel: boolean;
  grid: boolean;
  canvasDragOn: boolean;
  editorTransform?: { [key: string]: number[] };
  showPasteWarning: boolean;
  isCheckingTermsAcceptance: boolean;
  needsToAcceptTerms: boolean;
  acceptingTerms: boolean;
  showElementNotInViewWarning: boolean;
  elementNotInViewProps: { link?: string; onConfirm?: () => void; onCancel?: () => void };
  showUpgradeWarningModal: boolean;
  upgradeModalMessage: string;
  audioSplitElement?: string | null;
  showPremiumFeatureModal: boolean;
  premiumFeatureModalTitle: string | null;
  premiumFeatureModalContent: string | null;
  premiumFeatureTriggerSource: UpgradeSubscriptionClickedEventTrigger | null;
  showAudioLimitModal: false | [AudioLimitsModalType, number];
  leftHandPanel: LeftHandPanel | null;
}

export type TemplateSaveStatus = 'STATUS_SAVING' | 'STATUS_CLONING' | 'STATUS_INACTIVE';

export interface TemplateState {
  saveStatus: TemplateSaveStatus;
  error?: Error | null;
  contentfulManagementToken: string | null;
  templatesByCategory: Array<ProjectTemplateCategory>;
  isFetchingTemplates: boolean;
  templatesSortOrder: SortOrder;
  canvasSizeFilter: string;
}

export interface NetworkState {
  isOnline: boolean;
}

export interface PlaybackState {
  playbackScribe: ExistingScribeModel | null;
  error: string | null;
  audioMuted?: boolean;
  showPlaybackView: boolean;
  startingElementId: string | null;
  startingSceneIndex: number | null;
  autoPlay: boolean;
  isRecordingAudio: boolean;
  previewAudioPlayback: boolean;
  audioUrl: string | null;
  playAudio: boolean;
  playbackIsReady: boolean;
  startPlayback: boolean;
  uploadingResourceToGpu: boolean;
}

export interface LoginState {
  uiState: string;
  apiError?: string | null;
}

export type RootState = {
  cursors: CursorsState;
  cropping: CroppingState;
  fonts: FontsState;
  download: DownloadState;
  scribes: ScribeState;
  router: RouterState;
  auth: AuthState;
  images: ImageState;
  autoSave: AutoSaveState;
  ui: UIState;
  templates: TemplateState;
  userSettings: UserSettingState;
  network: NetworkState;
  marketing: MarketingState;
  login: LoginState;
  playback: PlaybackState;
  checkout: CheckoutState;
  audio: AudioState;
  audioUpload: AudioUploadState;
  audioLibrary: AudioLibraryState;
  aiFeatures: aiFeaturesState;
  projectScripts: ProjectScriptsState;
  imageDrawer: ImageDrawerState;
  imageSearch: ImageSearchState;
  limits: Limits;
};

export type TrackingState = {
  category: string | null;
};

export type CheckoutState = {
  step: 'INACTIVE' | 'CHECKING_OUT' | 'CHECKING_UPGRADE' | 'ASSIGN_LICENSE_POPUP';
};

export type DownloadTrackingTemplateProperties = {
  'Scribe Template ID'?: string;
  'Scribe Template Title'?: string;
  'Template Category'?: string;
};

export type DownloadTrackingAudioProperties = {
  'Total Number of Audio Clips': number;
  'Total Audio Clips Asset Size': number;
  'Number of Scene Audio Clips': number;
  'Number of Project Audio Clips': number;
};

export type DownloadTrackingProperties = {
  'Scribe ID'?: number;
  'File Type': string;
  'Scribe Length': number;
  'Number of Scenes': number;
  'Number of Elements': number;
  'Scribe Type': string;
  'Total Image Asset Size': number;
  'Font Faces Used': Array<string>;
  'Total Number of Font Faces': number;
} & DownloadTrackingTemplateProperties &
  DownloadTrackingAudioProperties;

export type VSLibraryImage = {
  id: string;
  title: string;
  thumbnailFilename: string;
  bucketUrl: string;
  contentType: string;
  isPremium: boolean;
};

export type GeneralSearchImage = VSLibraryImage & {
  imageFilename: string;
  pricingTier: string;
  communityExchangeImage: string;
  elementFacets: Array<ElementFacet>;
};

export type PremiumSearchImage = VSLibraryImage;

export type ImageCategory = {
  id: string;
  editorName: string;
  availableOffline: boolean;
  title: string;
  title_es: string;
  title_pt?: string;
  images?: Array<VSLibraryImage>;
  cursor?: string;
  thumbnailUrl?: string;
};

export interface RecolorDefaults {
  primaryColor?: string;
  secondaryColor?: string;
  strokeColor?: string;
  skinColor?: string;
  hairColor?: string;
  highlightColor?: string;
  nounColor?: string;
}

export type UploadProvider = 'upload' | 'user' | 'library' | 'noun-project' | 'ai-library';

export type ImageSource = 'videoscribe' | 'noun-project';

export interface ImageSearchMemo {
  searchTerm: string;
  searchSource: string;
}

export interface ImageState {
  loadingLibraries: boolean;
  libraries: Array<ImageCategory>;
  nounProjectLibraries: Array<ImageCategory>;
  search: ImageSearch;
  searchNounProject: ImageSearch;
  userImagesLoading: boolean;
  userImageAssets: Array<VSCAssetData>;
  userImagePage: number;
  userImagePageSize: number;
  userImages: Array<VSCAssetImageData>;
  searchMemo?: ImageSearchMemo;
  userAiImagesLoading: boolean;
  userAiImageAssets: Array<VSCAssetData>;
  userAiImagePage: number;
  userAiImages: Array<VSCAssetImageData>;
  userLibrary: UserImageLibraryCollection;
  replacingImageElementId: string | null;
}

export type UserImageLibrary = {
  loading: boolean;
  loadingSortedByLastUsed: boolean;
  sourceAssets: VSCAssetData[];
  images: VSCAssetImageData[];
  imagesSortedByLastUsed: VSCAssetImageData[];
};

export type UserImageLibraryCollection = {
  [key in VSCAssetImageSourceName]: UserImageLibrary;
};

export interface ElementFacet {
  id: string;
  name: string;
  value: string;
}

export interface SearchResult {
  pricingTier: string;
  communityExchangeImage: string;
  elementFacets: Array<ElementFacet>;
  id: string;
  title: string;
  bucketUrl: string;
  imageFilename: string;
  thumbnailFilename: string;
}

export interface ImageSearch {
  query?: string;
  results: Array<SearchResult> | null;
  totalImages?: number;
}

export type VSDimensions = {
  width: number;
  height: number;
};

export type TimelineDraggablePosition = {
  index: number;
  droppableId: 'sceneList' | 'elementList';
};

export enum AudioSource {
  UPLOADED = 'uploaded',
  RECORDED = 'recorded',
  AI_GENERATED = 'ai-generated'
}

export type AudioType = 'background' | 'voiceover' | AdvancedAudioType;
export type AdvancedAudioType = 'scene' | 'project';

export type AudioConfig = {
  volume?: number;
  volumeType?: AudioVolumeInputType;
  fadeOutDurationSeconds?: number;
  startOffset?: number;
  startTime?: number;
  duration?: number;
  instanceDuration?: number;
  type?: AudioType;
};

export type AudioVolumeInputType = 'Slider' | 'Input';

export type LockEventTrigger = 'Context Menu' | 'Edit Panel';

export type CameraPinEventTrigger = 'Context Menu' | 'Edit Panel' | typeof ON_ELEMENT_TRIGGER;

export type HideEventTrigger = 'Context Menu' | 'Edit Panel' | 'Keyboard';

export type ElementAnimationStageDurationKey =
  | 'animationTime'
  | 'emphasisAnimationTime'
  | 'exitAnimationTime'
  | 'pauseTime';

export type CameraAnimationStageDurationKey = 'animationTime' | 'pauseTime';

export type ElementAnimationStage = 'entrance' | 'emphasis' | 'exit';
export type ElementAnimationConfigurationKey =
  | typeof ENTRANCE_ANIMATION_DOCUMENT_KEY
  | typeof EMPHASIS_ANIMATION_DOCUMENT_KEY
  | typeof EXIT_ANIMATION_DOCUMENT_KEY;

export type ElementAnimationStageLoopsKey = 'emphasisAnimationLoops';

export type SceneTriggers = 'Scene Panel' | 'Context Menu';

export interface ProjectTemplate {
  title: string;
  id: string;
  isTemplate: boolean;
  sparkolTemplateId: string;
  isPremium: boolean;
  previewUrl: string;
  thumbnailImage: string;
  createdDate: Date;
  canvasSize: CanvasSize;
}

export interface ProjectTemplateCategory {
  id: string;
  title: string;
  templates: Array<ProjectTemplate>;
}

export interface CalloutCallToAction {
  type: NEXT_ACTION_TYPES;
  label: string;
}

export interface Callout {
  title: string;
  details?: string | JSX.Element;
  isError?: boolean;
  autoDismiss?: boolean;
  callToAction?: CalloutCallToAction;
}

export interface CalloutError {
  message: string;
  description?: string;
}
export type CursorResourceAndMetadata = {
  [key: string]: {
    handOffsetX: number;
    handOffsetY: number;
    resources: Array<string>;
  };
};

export type PlaybackScribeModel = ScribeModel & {
  cursorData: {
    resourcesAndMetadata: CursorResourceAndMetadata;
    timings: Array<CursorTiming>;
  };
};

interface VSCSVGResourceMetadata extends PIXI.IResourceMetadata {
  strokesTexture: PIXI.Texture;
  fillsTexture: PIXI.Texture;
  pixels: Uint8ClampedArray;
  splitSVGData: {
    svgParts: {
      meta: NodeListOf<SVGGeometryElement>;
      revealPaths: NodeListOf<SVGGeometryElement>;
      strokes: NodeListOf<SVGGeometryElement>;
      fills: NodeListOf<SVGGeometryElement>;
    };
    viewBox: string;
  };
  /**
   * If this SVG was converted from a raster image
   */
  isVectorizedImage: boolean;
}
export interface VSCSVGLoaderResource extends PIXI.LoaderResource {
  metadata: VSCSVGResourceMetadata;
}

export type PlaybackImageResources = {
  gifResources: PIXI.utils.Dict<PIXI.LoaderResource>;
  rasterResources: PIXI.utils.Dict<PIXI.LoaderResource>;
  svgResources: PIXI.utils.Dict<VSCSVGLoaderResource>;
};

export interface UserPersonaAnswers {
  user_type: string;
  intention: Array<string>;
}

export interface UpgradeButtonClickExtraEventProps {
  'Template Title': string;
  'Template Category': string;
  'Template Button Location': string;
}

export interface TemplatePreview {
  title: string;
  id: string;
  isTemplate: boolean;
  sparkolTemplateId: string;
  isPremium: boolean;
  previewUrl: string;
  thumbnailImage: string;
}

export type DirectionType = 'horizontal' | 'vertical';
export type ColorType = 'Single Colour' | 'Gradient';
export type ColorTab = 'Default' | 'Custom';
export type ColorLayer =
  | 'primaryColor'
  | 'secondaryColor'
  | 'strokeColor'
  | 'skinColor'
  | 'hairColor'
  | 'highlightColor';

export interface UserSettingState {
  loadingUserSettings: boolean;
  settings: UserSettingsOptions;
}

export interface UserSettingsOptions {
  recordWithPlayback?: boolean;
  hidePlaybackWarning?: boolean;
  suppressSvgCropWarning?: boolean;
  dontShowOutOfCameraWarning?: boolean;
  customColours?: string[];
  dontShowEnableColorChangeWarning?: boolean;
}

export interface MarketingAnswer {
  user_type: string;
  intention: Array<string>;
}

export interface MarketingState {
  showUserpersonaModal: boolean;
  personaDismissDate?: Date;
  personaDontShowAgain?: boolean;
}
export type VSError = Error & {
  errorCode?: string;
  err?: string;
};

export type HTTPError = Error & {
  httpStatusCode?: number | string;
  err?: string;
};

export type AccountType = 'FREE' | 'PLUS' | 'PRO' | 'EDU' | 'LITE' | 'CORE' | 'MAX';

export type AccountTerm = 'monthly' | 'yearly' | null;

export enum AccountContentKey {
  ACCOUNT_NEXT_ACTION,
  NOUN_PROJECT_WARNING_MSG,
  DURATION_LIMITS_PANEL_TOOLTIP,
  CUSTOM_FONT_WARNING_MSG,
  PREVIEW_LINK_WARNING_MSG,
  DOWNLOAD_UPSELL_MSG,
  PREMIUM_CONTENT_UPSELL_MSG,
  PREMIUM_CONTENT_IMAGE_LIBRARY_UPSELL_MSG,
  TIMELINE_TILE_PREMIUM_CONTENT_UPSELL_MSG
}

export interface RenderProgressSuccessResult {
  videoRequestComplete: boolean;
  segmentTotal: number;
  segmentRendersStarted: number;
  segmentRendersCompleted: number;
  segmentTranscodesCompleted: number;
  transcodeComplete: boolean;
  mergeComplete: boolean;
  errors: Array<string>;
}

export type AnimationStage = 'entrance' | 'emphasis' | 'exit';
export interface TweenPosition {
  id: string;
  position: string;
}
export interface TextStylingConfig {
  font: FontAttributes;
  align: TextAlignValue;
  fontWeight: TextFontWeight;
  fontStyle: TextFontStyle;
  fill: string;
  opacity: number;
  fontSize: number;
}

export interface UpdateElementProperty {
  opacity?: number;
  fill?: string;
  backgroundType?: BackgroundType;
  backgroundGradientType?: DirectionType;
  backgroundColorFrom?: string;
  backgroundColorTo?: string;
  unlockedRatio?: boolean;
  flipX?: boolean;
  flipY?: boolean;
  align?: TextAlignValue;
  fontWeight?: TextFontWeight;
  fontStyle?: TextFontStyle;
  font?: FontAttributes;
  scaleX?: number;
  scaleY?: number;
  fontSize?: number | string;
}
export interface ElementMenuProps {
  element: VSImageShapeOrTextElementModel;
  elements: Array<VSImageShapeOrTextElementModel>;
  scribe: ExistingScribeModel;
  onSelection: () => void;
  stageKey: ElementAnimationStage;
}

export type AnimationStageDocumentKey =
  | typeof ENTRANCE_ANIMATION_DOCUMENT_KEY
  | typeof EMPHASIS_ANIMATION_DOCUMENT_KEY
  | typeof EXIT_ANIMATION_DOCUMENT_KEY;

export type SignedUrl = {
  assetId: number;
  url: string;
  thumbs?: Array<{
    url: string;
    size: string;
  }>;
};

export type Viewport = {
  width: number;
  height: number;
  resolution: number;
};

export type MaskingLineStyleConfig = { width: number; color: number; cap: PIXI.LINE_CAP; join: PIXI.LINE_JOIN };

export interface VSCoordinate {
  x: number;
  y: number;
}

export interface VSCoordinateWithBrushSize extends VSCoordinate {
  brushSize: number;
}

export type AnimationTimingOptions = {
  startTimeMs: number;
  endTimeMs: number;
};

export type SceneTransitionKey =
  | typeof SCENE_TRANSITION_KEY_NONE
  | typeof SCENE_TRANSITION_KEY_SLIDE
  | typeof SCENE_TRANSITION_KEY_FADE
  | typeof SCENE_TRANSITION_KEY_BOUNCE
  | typeof SCENE_TRANSITION_KEY_ERASE
  | typeof SCENE_TRANSITION_KEY_DRAG_ON;

export type SceneTransitionClass =
  | VSSlideTransition
  | VSFadeTransition
  | VSBounceTransition
  | VSEraseTransition
  | VSDragOnTransition;

export type SceneTransitionClassMap =
  | null
  | typeof VSSlideTransition
  | typeof VSFadeTransition
  | typeof VSBounceTransition
  | typeof VSEraseTransition
  | typeof VSDragOnTransition;

export type VSAnimationElement = VSRasterImage | VSVectorImage | VSTextElement | VSShapeElement;

export type SceneTransitionConfigProp = Omit<SceneTransitionConfig, 'cursorId'> & {
  cursorId?: ScribeCursorId | 'Mixed';
};

export interface SceneChoiceButtonProps {
  scribeId: number;
  sceneId?: string | undefined;
  config: SceneTransitionConfigProp;
  animationType: string;
  useAllDirections: boolean;
}

export interface ElementChoiceButtonProps {
  element: VSElementModel | undefined;
  elements: Array<VSElementModel> | undefined;
  stageKey: ElementAnimationStage;
  flipImage: boolean;
  scribe: ExistingScribeModel;
}

export interface VSComponentProps {
  onSelection: () => void;
  config: SceneTransitionConfigProp;
  sceneIds?: string[];
  scribeId: number;
  useAllDirections: boolean;
}

export interface TransitionSubMenu<T, R> {
  title: string;
  Component: (props: R) => JSX.Element;
  choiceButtonLabel: string;
  ChoiceButton: (props: T) => JSX.Element;
  useAllDirections?: boolean;
}

export interface AnimationTypeOption<T, R> {
  type: AllAnimationTypeKeys;
  title: string;
  Icon: ({ flipImage }: { flipImage?: boolean }) => JSX.Element;
  secondaryMenu?: TransitionSubMenu<T, R>;
  tertiaryMenu?: TransitionSubMenu<T, R>;
}

export type ProjectModel = ScribeModelProps & {
  elements?: Array<VSElements>;
};

export interface ScribeObject {
  projectModel: ProjectModel;
  projectDataId: number;
  createdDate: string;
  modifiedDate?: string;
  version?: string | number;
  viewOnly?: boolean;
  publicSharingId?: string;
  title: string;
}

// Move into ContextMenu file when this is typescript
export interface ContextMenuProps {
  type: string;
  elementId?: string;
  mouseX: number;
  mouseY: number;
  scribeId: number;
}

export type ShapeElement = CircleElement | SquareElement | TriangleElement | EllipseElement;
export interface PaddlePricesPayload {
  country: string;
  recurring: {
    price: {
      gross: string;
    };
  };
}

export interface CheckoutSuccessPayloadItem {
  product_id: number;
  name: string;
  quantity: number;
  recurring: {
    line_price: {
      gross_after_discount: number;
    };
  };
}

export interface CheckoutSuccessPayload {
  checkout: {
    coupon: {
      coupon_code: string;
    };
    recurring_prices: {
      id: string;
      customer: {
        currency: string;
        total: number;
        items: Array<CheckoutSuccessPayloadItem>;
      };
    };
  };
  user: {
    id: number;
  };
}

export interface MaskPathAndBrushData {
  maskPath: Array<VSCoordinate>;
  brushSize: number;
}
export interface ScribeCameraElement {
  id: string;
  x: number;
  y: number;
  type: 'Camera';
  scale: number;
  animationTime: number;
  pauseTime: number;
  locked: boolean;
  cameraPinned: boolean;
  easingType?: typeof CAMERA_EASING_NONE | typeof CAMERA_EASING_EASEOUT | typeof CAMERA_EASING_LINEAR;
}
export interface ConvertedSVGData {
  /**
   * data URL of the SVG converted into the VS format
   */
  encodedData: string;
  /**
   * viewBox setting of the processed SVG
   */
  viewBox: string;
  /**
   * SVG string of SVG converted into the VS format
   */
  convertedSVGString: string;
  /**
   * If this SVG was converted from a raster image
   */
  isVectorizedImage: boolean;
}

export interface SVGAsset {
  key: string;
  url: string;
  svgWidth: number;
  svgHeight: number;
}
export interface GIFAsset {
  url: string;
  assetId: number;
}

export type ExtractedGIFData = {
  frames: Array<string>;
  totalFrames: number;
  loopCount: number;
  gifLengthMs: number;
  frameTimes: Array<{
    startTimeMs: number;
    endTimeMs: number;
  }>;
};

export interface CursorTiming {
  cursorId: ScribeCursorId;
  startTime: number;
  endTime: number;
}

export interface SVGMaskPathAndBrushData {
  scribbleArray: Array<VSCoordinate>;
  brushSize: number;
}

export interface ViewportRectAndCenter {
  viewportRectangle: PIXI.Rectangle;
  viewportCenter: PIXI.Point;
}

export interface VSCAudioLibraryApiData {
  resources: {
    full: {
      url: string;
    };
  };
  genres: number | string;
  id: number;
  name: string;
  author: string;
  loopFriendly: 'true' | 'false'; // 🙄
  time: string;
  tempo: number;
}

export interface VSCAudioLibraryTrack {
  url: string;
  genres: Array<string>;
  title: string;
  duration: number;
  durationLabel: string;
  tempo: number;
  tempoLabel: string;
  loopFriendly: boolean;
  id: number;
}

export interface AudioLibraryState {
  loading: boolean;
  tracks: Array<VSCAudioLibraryTrack>;
  creatingFromAsset: null | number;
}

export type AudioLimitsModalType = 'size' | 'tracks';

// List of all the feature flags
export type AiFeatureFlags = typeof IMAGE_GENERATION | typeof VOICE_GENERATION | typeof SCRIPT_GENERATION;

export enum AiPanelTab {
  FEATURES = 'features',
  IMAGE_GENERATION = 'image_generation',
  VOICE_GENERATION = 'voice_generation',
  SCRIPT_GENERATION = 'script_generation'
}

export enum AiFeaturesTabStep {
  OVERVIEW = 'features_overview'
}

export enum AiImageGenerationTabStep {
  OVERVIEW = 'image_gen_overview',
  RESULTS = 'image_gen_results'
}

export enum AiVoiceGenerationTabStep {
  OVERVIEW = 'voice_gen_overview',
  RESULTS = 'voice_gen_results'
}

export enum AiScriptGenerationTabStep {
  OVERVIEW = 'script_gen_overview',
  RESULTS = 'script_gen_results'
}

export type AiPanelStep =
  | AiFeaturesTabStep
  | AiImageGenerationTabStep
  | AiVoiceGenerationTabStep
  | AiScriptGenerationTabStep;

export type ImageGenerationStyle = keyof typeof IMAGE_GEN_STYLES;

export type ImageGenerationInputs = {
  style: ImageGenerationStyle;
  prompt: string;
};

export type ImageGenerationOutputs = {
  id: string | null;
  images: Array<{
    url: string;
    assetId: number;
  }>;
  error: string | null;
  enhancedPrompt: string | null;
};

export enum ImageGenerationStatus {
  NOT_STARTED = 'not started',
  IN_PROGRESS = 'in progress',
  COMPLETE = 'complete',
  FAILED = 'failed'
}

export type InputVoiceData = {
  displayedName: string;
  originalName: string;
  gender: string;
};

export type VoiceoverGenerationInputs = {
  prompt: string;
  language: string;
  voice: InputVoiceData;
};

export enum VoiceoverGenerationStatus {
  NOT_STARTED = 'Not started',
  PENDING = 'Pending',
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed'
}

export type VoiceoverGenerationOutputs = {
  id: string | null;
  url: string | null;
  error: string | null;
  assetId: number | null;
  rating: RatingOption | null;
};

export type ScriptGenerationInputs = {
  prompt: string;
  lengthOfScriptMinutes: number;
  lengthOfScriptSeconds: number;
  numberOfScenes: number;
  predefinedStyles: TagOption[];
  customStyle: string;
};

export type GeneratedScene = {
  scene: number;
  narration: string;
};

export type ScriptGenerationApiData = {
  policyViolation: boolean;
  scenes: GeneratedScene[];
};

export type ScriptGenerationResults = {
  script: string;
};

export type AiTabsState = {
  [AiPanelTab.FEATURES]: {
    step: AiFeaturesTabStep;
  };
  [AiPanelTab.IMAGE_GENERATION]: {
    step: AiImageGenerationTabStep;
  };
  [AiPanelTab.VOICE_GENERATION]: {
    step: AiVoiceGenerationTabStep;
  };
  [AiPanelTab.SCRIPT_GENERATION]: {
    step: AiScriptGenerationTabStep;
  };
};

export interface aiFeaturesState {
  panelTab: AiPanelTab;
  tabState: AiTabsState;
  imageGenerationInputs: Partial<ImageGenerationInputs>;
  imageGenerationStatus: ImageGenerationStatus;
  imageGenerationResults: ImageGenerationOutputs;
  voiceoverGenerationInputs: Partial<VoiceoverGenerationInputs>;
  voiceoverGenerationStatus: VoiceoverGenerationStatus;
  voiceoverGenerationResults: VoiceoverGenerationOutputs;
  scriptGenerationInputs: ScriptGenerationInputs;
  scriptGenerationLoading: boolean;
  scriptGenerationResults: ScriptGenerationResults;
  feedbackRatings: {
    voiceover: RatingOption | undefined;
    script: RatingOption | undefined;
  };
  feedbackTags: {
    voiceover: TagOption[];
    script: TagOption[];
  };
  feedbackComments: {
    voiceover: string;
    script: string;
  };
}

export enum AiFeature {
  IMAGE = 'image',
  VOICEOVER = 'voiceover',
  SCRIPT = 'script'
}

export enum ImageGenerationApiFailReason {
  PROMPT_REJECTED = 'Prompt rejected',
  SERVER_ERROR = 'Server error',
  TIMEOUT = 'Timeout'
}

export type ImageGenerationProgressApiData =
  | {
      status: ImageGenerationStatus.COMPLETE;
      signedUrls: string[];
      id: string;
      metadata: {
        enhancedPrompt: string;
      };
    }
  | {
      status: ImageGenerationStatus.IN_PROGRESS;
    }
  | {
      status: ImageGenerationStatus.FAILED;
      failedReason: ImageGenerationApiFailReason;
    };

export type ImageGenerationApiData = {
  executionId: string;
};

export enum RatingOption {
  NEGATIVE = 'negative',
  OK = 'ok',
  POSITIVE = 'positive'
}

export enum AddAIGenerationTrigger {
  AI_LHP = 'AI LHP'
}

export enum AddUserImageToProjectEventTrigger {
  YOUR_FILES_UPLOADS = 'Your files - uploads',
  YOUR_FILES_AI = 'Your files - ai-library',
  RECENTLY_USED_ILLUSTRATIONS = 'Recently used - library',
  RECENTLY_USED_ICONS = 'Recently used - noun-project',
  RECENTLY_USED_UPLOADS = 'Recently used - uploads',
  RECENTLY_USED_AI = 'Recently used - ai images'
}

export type VoiceoverGenerationApiData = {
  executionId: string;
};

export enum VoiceoverGenerationApiFailReason {
  SERVER_ERROR = 'Server error',
  TIMEOUT = 'Timeout'
}

export type VoiceoverGenerationProgressApiData =
  | {
      status: VoiceoverGenerationStatus.SUCCEEDED;
      url: string;
      id: string;
      generatedFileDurationMs: number;
      voiceoverSecondsUsed: number;
    }
  | {
      status: VoiceoverGenerationStatus.PENDING;
      id: string;
    }
  | {
      status: VoiceoverGenerationStatus.FAILED;
      failedReason: VoiceoverGenerationApiFailReason;
    };

export enum ImageDrawerTab {
  IMAGE_LIBRARY = 'Image library',
  YOUR_FILES = 'Your files',
  RECENTLY_USED = 'Recently used'
}

export type ImageLibraryCategory = {
  loading: boolean;
  nextPageId: string | null;
  images: Array<VSLibraryImage>;
  total?: number; // To be implemented
};

export type ImageLibrarySearch<T> = {
  loading: boolean;
  offset: number;
  total: number;
  nextPageId?: string;
  images: Array<T>;
  isViewed: boolean;
};

export interface ImageDrawerState {
  panelTab: ImageDrawerTab;
  imageLibrary: ImageLibraryState;
}
export interface ImageLibraryState {
  category: {
    videoscribe: {
      [id: string]: ImageLibraryCategory;
    };
    'noun-project': {
      [id: string]: ImageLibraryCategory;
    };
  };
  search: {
    videoscribe: {
      [keyword: string]: ImageLibrarySearch<GeneralSearchImage>;
    };
    'noun-project': {
      [keyword: string]: ImageLibrarySearch<PremiumSearchImage>;
    };
  };
}

export type VisualCanvasElement = SVGImageElement | ImageElement | ShapeElement | TextElement;

export type ImageLibraryCategorySearch = {
  cursor?: string;
  images: Array<VSLibraryImage>;
};

export type ImageLibraryInputSearch = {
  data: Array<{
    images: Array<GeneralSearchImage> | Array<PremiumSearchImage>;
  }>;
  meta: {
    count: number;
    error: null | string;
    offset: number;
    total: number;
    next?: string;
  };
};

export type Limits = {
  [key in LIMITS]: {
    count: number;
    resetDate: string;
    limit: number | null;
    limitUpgrades: AccountType[];
    units: 'seconds' | 'count';
  };
};
