import { IMAGE_GENERATION, IMAGE_LIBRARY_REWORK } from 'js/config/featureKeys';

import getOptimizelyInstance from './optimizely';

const RUNNING_EXPERIMENTS_TO_TRACK: Array<string> = [];
const RUNNING_FEATURE_FLAGS_TO_TRACK: Array<string> = [IMAGE_GENERATION, IMAGE_LIBRARY_REWORK];

export function getExperimentAndFlagsUserPropertiesForTracking(
  experiments = RUNNING_EXPERIMENTS_TO_TRACK,
  flags = RUNNING_FEATURE_FLAGS_TO_TRACK
): { [key: string]: string } {
  const optimizely = getOptimizelyInstance();

  if (!optimizely) return {};

  const decisions = optimizely.decideForKeys(experiments);
  const experimentUserProperties: { [key: string]: string } = Object.entries(decisions).reduce(
    (properties, [experimentKey, decision]) => {
      return {
        ...properties,
        [`Experiment: ${experimentKey}`]: decision.variationKey
      };
    },
    {}
  );

  flags.forEach(flag => {
    const enabled = optimizely.isFeatureEnabled(flag);
    experimentUserProperties[`Feature Flag "${flag}" Status`] = enabled ? 'Enabled' : 'Disabled';
  });

  return experimentUserProperties;
}
