import {
  AudioLimitsModalType,
  AiPanelTab,
  Callout,
  CalloutError,
  HomeViewOptions,
  ProjectsViewMode,
  LeftHandPanel
} from 'js/types';
import { WARNING_MESSAGE, TRIGGER_SOURCE } from 'js/home/UpgradeWarningModal/consts';
import { UpgradeSubscriptionClickedEventTrigger } from 'js/actionCreators/trackingActions';

import { ChangeStepTrigger } from './aiFeaturesActions';

export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_CALLOUT = 'SHOW_CALLOUT';
export const HIDE_CALLOUT = 'HIDE_CALLOUT';
export const SHOW_ERROR = 'SHOW_ERROR';
export const UNLOAD_PLAYBACK = 'UNLOAD_PLAYBACK';
export const LOAD_PLAYBACK = 'LOAD_PLAYBACK';
export const LOADED_PLAYBACK_ASSETS = 'LOADED_PLAYBACK_ASSETS';
export const UI_LOADING = 'UI_LOADING';
export const CURRENT_HOME_VIEW = 'CURRENT_HOME_VIEW';
export const AUDIO_RECORDING_START = 'AUDIO_RECORDING_START';
export const AUDIO_RECORDING_RESET = 'AUDIO_RECORDING_RESET';
export const SHOW_RECORD_PANEL = 'SHOW_RECORD_PANEL';
export const CURRENT_EDIT_ANIMATION_TAB = 'CURRENT_EDIT_ANIMATION_TAB';
export const APPLICATION_FONTS_LOADED = 'APPLICATION_FONTS_LOADED';
export const SET_EDITOR_LOADING_MESSAGE = 'SET_EDITOR_LOADING_MESSAGE';
export const PREVIEW_LINK_LOADING = 'PREVIEW_LINK_LOADING';
export const SET_PROJECTS_VIEW_MODE = 'SET_PROJECTS_VIEW_MODE';
export const SHOW_ELEMENTS_PANEL = 'SHOW_ELEMENTS_PANEL';
export const SHOW_PASTE_WARNING = 'SHOW_PASTE_WARNING';
export const CLOSE_PASTE_WARNING = 'CLOSE_PASTE_WARNING';
export const SHOW_ELEMENT_NOT_IN_VIEW_WARNING = 'SHOW_ELEMENT_NOT_IN_VIEW_WARNING';
export const CLOSE_ELEMENT_NOT_IN_VIEW_WARNING = 'CLOSE_ELEMENT_NOT_IN_VIEW_WARNING';
export const OPEN_RESOURCE_CENTER = 'OPEN_RESOURCE_CENTER';
export const SHOW_UPGRADE_WARNING_MODAL = 'SHOW_UPGRADE_WARNING_MODAL';
export const HIDE_UPGRADE_WARNING_MODAL = 'HIDE_UPGRADE_WARNING_MODAL';
export const SET_SPLIT_MODE = 'SET_SPLIT_MODE';
export const SHOW_PREMIUM_FEATURE_MODAL = 'SHOW_PREMIUM_FEATURE_MODAL';
export const CLOSE_PREMIUM_FEATURE_MODAL = 'CLOSE_PREMIUM_FEATURE_MODAL';
export const SHOW_AUDIO_LIMITS_MODAL = 'SHOW_AUDIO_LIMITS_MODAL';
export const DISMISS_AUDIO_LIMITS_MODAL = 'DISMISS_AUDIO_LIMITS_MODAL';
export const SHOW_LEFT_HAND_PANEL = 'SHOW_LEFT_HAND_PANEL';
export const CLOSE_LEFT_HAND_PANEL = 'CLOSE_LEFT_HAND_PANEL';

export const EDITOR_MODES = {
  EDIT: 'EDIT',
  IMAGE_CROP: 'IMAGE_CROP',
  default: 'EDIT'
};

export interface HideModalAction {
  type: typeof HIDE_MODAL;
  scribeId?: number;
  modal?: string;
}

export const hideModal = (scribeId?: number, modal?: string): HideModalAction => ({
  type: HIDE_MODAL,
  scribeId,
  modal
});

export interface ShowCalloutAction {
  type: typeof SHOW_CALLOUT;
  callout: Callout;
}

export const showCallout = (callout: Callout): ShowCalloutAction => ({
  type: SHOW_CALLOUT,
  callout
});

export const openResourceCenter = () => ({
  type: OPEN_RESOURCE_CENTER
});

export const hideCallout = () => ({
  type: HIDE_CALLOUT
});

export interface ShowErrorAction {
  type: typeof SHOW_ERROR;
  error: CalloutError;
}

export const showError = (error: CalloutError): ShowErrorAction => ({
  type: SHOW_ERROR,
  error
});

export interface UnloadPlaybackAction {
  type: typeof UNLOAD_PLAYBACK;
  scribeId: number;
}

export const unloadPlayback = (scribeId: number): UnloadPlaybackAction => ({
  type: UNLOAD_PLAYBACK,
  scribeId
});

export interface LoadPlaybackAction {
  type: typeof LOAD_PLAYBACK;
  showLoading: boolean;
}

export const loadPlayback = (showLoading = false): LoadPlaybackAction => ({
  type: LOAD_PLAYBACK,
  showLoading
});

export const loadedPlaybackAssets = () => ({
  type: LOADED_PLAYBACK_ASSETS
});

export interface StartLoadingAction {
  type: typeof UI_LOADING;
  isLoading: boolean;
}

export const startLoading = (): StartLoadingAction => ({
  type: UI_LOADING,
  isLoading: true
});

export interface StopLoadingAction {
  type: typeof UI_LOADING;
  isLoading: boolean;
}

export const stopLoading = (): StopLoadingAction => ({
  type: UI_LOADING,
  isLoading: false
});

export interface SetCurrentHomeViewAction {
  type: typeof CURRENT_HOME_VIEW;
  currentHomeView: HomeViewOptions;
}

export const setCurrentHomeView = (currentHomeView: HomeViewOptions): SetCurrentHomeViewAction => ({
  type: CURRENT_HOME_VIEW,
  currentHomeView
});

export interface AudioRecordingStartAction {
  type: typeof AUDIO_RECORDING_START;
  scribeId: number;
  audioType: string;
}

export const audioRecordingStart = (scribeId: number, audioType: string): AudioRecordingStartAction => ({
  type: AUDIO_RECORDING_START,
  scribeId,
  audioType
});

export interface AudioRecordingCancelAction {
  type: typeof AUDIO_RECORDING_RESET;
  scribeId: number;
  audioType: string;
}

export const audioRecordingReset = (scribeId: number, audioType: string): AudioRecordingCancelAction => ({
  type: AUDIO_RECORDING_RESET,
  scribeId,
  audioType
});

export const showRecordPanel = () => ({
  type: SHOW_RECORD_PANEL
});

export interface SetCurrentEditAnimationTabAction {
  type: typeof CURRENT_EDIT_ANIMATION_TAB;
  currentEditAnimationTab: 'animation' | 'edit';
}

export const setCurrentEditAnimationTab = (
  currentEditAnimationTab: 'animation' | 'edit'
): SetCurrentEditAnimationTabAction => ({
  type: CURRENT_EDIT_ANIMATION_TAB,
  currentEditAnimationTab
});

export const applicationFontsLoaded = () => ({ type: APPLICATION_FONTS_LOADED });

export interface SetEditorLoadingMessageAction {
  type: typeof SET_EDITOR_LOADING_MESSAGE;
  message: string;
}

export const setEditorLoadingMessage = (message: string): SetEditorLoadingMessageAction => ({
  type: SET_EDITOR_LOADING_MESSAGE,
  message
});

export interface PreviewLinkLoadingAction {
  type: typeof PREVIEW_LINK_LOADING;
  isLoading: boolean;
}

export const previewLinkLoading = (isLoading: boolean): PreviewLinkLoadingAction => ({
  type: PREVIEW_LINK_LOADING,
  isLoading
});

export interface SetProjectsViewModeAction {
  type: typeof SET_PROJECTS_VIEW_MODE;
  mode: ProjectsViewMode;
}

export const setProjectsViewMode = (mode: ProjectsViewMode): SetProjectsViewModeAction => ({
  type: SET_PROJECTS_VIEW_MODE,
  mode
});

export interface ShowElementsPanelAction {
  type: typeof SHOW_ELEMENTS_PANEL;
  value: boolean;
}

export const showElementsPanel = (value: boolean): ShowElementsPanelAction => ({
  type: SHOW_ELEMENTS_PANEL,
  value
});

export interface ShowPasteWarningAction {
  type: typeof SHOW_PASTE_WARNING;
}

export const showPasteWarning = (): ShowPasteWarningAction => ({
  type: SHOW_PASTE_WARNING
});

export interface ClosePasteWarningAction {
  type: typeof CLOSE_PASTE_WARNING;
}

export const closePasteWaning = (): ClosePasteWarningAction => ({
  type: CLOSE_PASTE_WARNING
});

export interface ShowElementNotInViewWarningAction {
  type: typeof SHOW_ELEMENT_NOT_IN_VIEW_WARNING;
  link?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const showElementNotInViewWarning = (
  link: string,
  onConfirm: () => void,
  onCancel: () => void
): ShowElementNotInViewWarningAction => {
  return {
    type: SHOW_ELEMENT_NOT_IN_VIEW_WARNING,
    link,
    onConfirm,
    onCancel
  };
};

export interface CloseElementNotInViewWarningAction {
  type: typeof CLOSE_ELEMENT_NOT_IN_VIEW_WARNING;
}

export const closeElementNotInViewWarning = (): CloseElementNotInViewWarningAction => ({
  type: CLOSE_ELEMENT_NOT_IN_VIEW_WARNING
});

export interface ShowUpgradeWarningModalAction {
  type: typeof SHOW_UPGRADE_WARNING_MODAL;
  message: WARNING_MESSAGE;
  triggerSource: TRIGGER_SOURCE;
}

export const showUpgradeWarningModal = (
  message: WARNING_MESSAGE,
  triggerSource: TRIGGER_SOURCE
): ShowUpgradeWarningModalAction => ({
  type: SHOW_UPGRADE_WARNING_MODAL,
  message,
  triggerSource
});

export interface HideUpgradeModalAction {
  type: typeof HIDE_UPGRADE_WARNING_MODAL;
}

export const hideUpgradeModal = (): HideUpgradeModalAction => ({
  type: HIDE_UPGRADE_WARNING_MODAL
});

export interface SetSplitModeAction {
  type: typeof SET_SPLIT_MODE;
  scribeId: number;
  id?: string | null;
  modeOn: boolean;
  trigger: 'Edit Panel' | 'Context Menu' | 'Advanced Timeline';
}
export type SetSplitModeProperties = Omit<SetSplitModeAction, 'type'>;
export const setSplitMode = ({ scribeId, id, modeOn, trigger }: SetSplitModeProperties): SetSplitModeAction => ({
  type: SET_SPLIT_MODE,
  scribeId,
  id,
  modeOn,
  trigger
});
export interface ShowPremiumFeatureModalAction {
  type: typeof SHOW_PREMIUM_FEATURE_MODAL;
  title: string;
  content: string;
  triggerSource: UpgradeSubscriptionClickedEventTrigger;
}

export const showPremiumFeatureModal = (
  title: string,
  content: string,
  triggerSource: UpgradeSubscriptionClickedEventTrigger
): ShowPremiumFeatureModalAction => ({
  type: SHOW_PREMIUM_FEATURE_MODAL,
  title,
  content,
  triggerSource
});

export interface ClosePremiumFeatureModalAction {
  type: typeof CLOSE_PREMIUM_FEATURE_MODAL;
}

export const closePremiumFeatureModal = (): ClosePremiumFeatureModalAction => ({
  type: CLOSE_PREMIUM_FEATURE_MODAL
});

export interface ShowAudioLimitsModalAction {
  type: typeof SHOW_AUDIO_LIMITS_MODAL;
  projectId: number;
  modalType: AudioLimitsModalType;
}
export const showAudioLimitsModal = (
  projectId: number,
  modalType: AudioLimitsModalType
): ShowAudioLimitsModalAction => ({
  type: SHOW_AUDIO_LIMITS_MODAL,
  projectId,
  modalType
});

export interface DismissAudioLimitsModalAction {
  type: typeof DISMISS_AUDIO_LIMITS_MODAL;
}
export const dismissAudioLimitsModal = (): DismissAudioLimitsModalAction => ({
  type: DISMISS_AUDIO_LIMITS_MODAL
});

interface ShowAiPanel {
  name: LeftHandPanel.AI_GENERATION;
  tab?: AiPanelTab;
  trigger?: ChangeStepTrigger;
}

interface ShowScenesPanel {
  name: LeftHandPanel.SCENES;
}

interface ShowLeftAudioPanel {
  name: LeftHandPanel.AUDIO;
}

interface ShowScriptDrawer {
  name: LeftHandPanel.SCRIPTS;
}

interface ShowImageDrawer {
  name: LeftHandPanel.IMAGES;
}

export type ShowLeftHandPanelAction = {
  type: typeof SHOW_LEFT_HAND_PANEL;
} & (ShowAiPanel | ShowScenesPanel | ShowLeftAudioPanel | ShowScriptDrawer | ShowImageDrawer);

export const showLeftHandPanel = (
  name: LeftHandPanel,
  tab?: AiPanelTab,
  trigger?: ChangeStepTrigger
): ShowLeftHandPanelAction => ({
  type: SHOW_LEFT_HAND_PANEL,
  name,
  tab,
  trigger
});

export interface CloseLeftHandPanelAction {
  type: typeof CLOSE_LEFT_HAND_PANEL;
}

export const closeLeftHandPanel = (): CloseLeftHandPanelAction => ({
  type: CLOSE_LEFT_HAND_PANEL
});
