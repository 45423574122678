import { call, select, take, takeEvery, fork, put } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import * as PIXI from 'pixi.js';
import { VALIDATE_TOKEN_SUCCESS } from 'js/actionCreators/authActions';
import {
  TRACK_PERSONA_ANSWERS,
  TRACK_OPEN_ACCOUNT_MANAGEMENT,
  TRACK_TEMPLATE_PREVIEW_CLICK,
  TRACK_TEMPLATE_SCROLL,
  TRACK_TEMPLATE_EDIT,
  TRACK_DOWNLOAD_FORMAT_SELECTION_CHANGE,
  TRACK_CURSOR_SELECTION_CHANGE,
  TRACK_CURSOR_FILTER_CHANGE,
  TRACK_UPDATE_SCRIBE_BACKGROUND_SETTINGS,
  TRACK_FULLSCREEN_ENTERED,
  TRACK_SELECT_ALL,
  TRACK_SET_ACTIVE_ELEMENTS_VISIBILITY,
  TRACK_CREATE_NEW_PROJECT,
  TRACK_CAMERA_WARNING_MODAL,
  leaveTemplatePreview
} from 'js/actionCreators/trackingActions';
import { SHOW_USERPERSONA_MODAL } from 'js/actionCreators/marketingActions';
import {
  ADD_SCRIBE_SUCCESS,
  UPDATE_SCRIBE_CURSOR,
  CREATE_SHAPE_ELEMENT,
  LOAD_SCRIBES_SUCCESS,
  UPDATE_PROJECT_TITLE
} from 'js/actionCreators/scribeActions';
import { ROUTE_CODES } from 'js/config/routes';
import { CREATE_SCRIBE_FROM_TEMPLATE } from 'js/actionCreators/templatesActions';
import getFileTypeFromFormat from 'js/shared/helpers/getFileTypeFromFormat';
import {
  SET_ANIMATION_TYPE,
  SET_ANIMATION_DIRECTION,
  SET_EMPHASIS_ANIMATION_DIRECTION
} from 'js/actionCreators/animationActions';
import { DUPLICATE_SCRIBE, DUPLICATE_SCRIBE_SUCCESS } from 'js/actionCreators/duplicateScribeActions';
import { selectableEntranceAnimationOptions, selectableShakeAnimationOptions } from 'js/config/animationOptions';
import getAllCursors from 'js/selectors/getAllCursors';
import { SET_PROJECTS_VIEW_MODE } from 'js/actionCreators/uiActions';
import { getExperimentAndFlagsUserPropertiesForTracking } from 'js/shared/helpers/getExperimentUserPropertiesForTracking';

import { getRoundedDaysRemaining } from '../sagaHelpers/getRoundedDaysRemaining';
import { getScribeById } from '../selectors';

import {
  track,
  identify,
  setUserProperties,
  setUserPropertiesOnce,
  registerEventSuperProperties,
  registerEventSuperPropertiesOnce,
  reset,
  incrementUserProperties,
  incrementEventSuperProperty
} from './mixpanelProvider';
import trackDownloadRequestsSagas from './saga/trackDownloadRequests';
import trackImagesSagas from './saga/trackImagesSagas';
import trackUiSagas from './saga/trackUiSagas';
import trackAudioSagas from './saga/trackAudioSagas';
import trackTimelineSagas from './saga/trackTimelineSagas';
import trackScribeListSagas from './saga/trackScribeListSagas';
import trackCanvasEvents from './saga/trackCanvasEvents';
import trackScenesSagas from './saga/trackScenesSagas';
import trackImageCroppingSagas from './saga/trackImageCroppingSagas';
import trackDuplicateScribeSaga from './saga/trackDuplicateScribeSaga';
import trackTemplateLoadedSaga, { trackServicesTemplateLoadedSaga } from './saga/trackTemplateLoadedSaga';
import trackElementCursorSelectionSagas from './saga/trackElementCursorSelection';
import trackUserFontsSaga from './saga/trackUserFontsSaga';
import trackPreviewSagas from './saga/trackPreviewSagas';
import trackSetProjectsViewMode from './saga/trackSetProjectsViewMode';
import manageTimelineSuperEventProperties from './saga/manageTimelineSuperEventProperties';
import trackCameraSagas from './saga/trackCameraSagas';
import { webGLMaxTextureSize } from './saga/webGLMaxTextureSize';
import trackElementAlignment from './saga/trackElementAlignment';
import trackAudioLibrarySagas from './saga/trackAudioLibrarySagas';
import trackSubscriptionSagas from './saga/trackSubscriptionSagas';
import trackLimitsSagas from './saga/trackLimitsSagas';
import trackAiSagas from './saga/trackAiSagas';
import trackImageDrawerSagas from './saga/trackImageDrawerSagas';

export function* trackLogin({ user }) {
  const trialDaysRemaining = getRoundedDaysRemaining(user.trialExpiryDate);
  const experimentUserProperties = getExperimentAndFlagsUserPropertiesForTracking();

  yield call(identify, user.id);
  yield call(setUserProperties, {
    $email: user.email,
    'Subscription Tier': user.accountType,
    'Licence Type': user.accountType,
    'Signup Date': user.signupDate,
    'Browser Supports WebGL': PIXI.utils.isWebGLSupported(),
    'Max Texture Size': webGLMaxTextureSize(),
    ...experimentUserProperties
  });
  yield call(registerEventSuperProperties, {
    'Subscription Tier': user.accountType,
    'Licence Type': user.accountType,
    'Subscription Term': user.term || '',
    'Free Trial Days Remaining': trialDaysRemaining,
    'Signup Date': user.signupDate
  });
  yield call(track, 'Re-auth');
}

export async function trackLogout() {
  const callbackPromise = new Promise(resolve => {
    resolve();
  });
  track('Log Out', null, { send_immediately: true }, callbackPromise);
  await callbackPromise;
  return reset(); // Clears super properties and generates a new random distinct_id for this instance.
}

export function* trackPersonaAnswers({ answers, date, userTriggered }) {
  if (userTriggered) {
    yield call(track, 'Select User Org', { type: answers.user_type });
    yield call(track, 'Select User Intent', { intention: answers.intention });
  }
  yield call(setUserPropertiesOnce, {
    'Organisation Type': answers.user_type,
    Intentions: answers.intention,
    'Completed User Persona Form at': date
  });
  yield call(registerEventSuperPropertiesOnce, {
    'Organisation Type': answers.user_type,
    Intentions: answers.intention
  });
}

function* trackPersonaModalOpen() {
  yield call(track, 'UserPers Modal Shown');
}

function* trackOpenAccountManagement() {
  yield call(track, 'Open Acc Manage');
}

function* trackNewScribeCreated({ scribe }) {
  if (scribe.source?.type === 'blank') {
    yield call(track, 'Create Blank Scribe', {
      'Canvas Size': scribe.canvasSize,
      'Scribe Type': scribe.source.type,
      'Scribe ID': scribe.id
    });
  }

  yield call(incrementUserProperties, {
    'Total Scribes Created': 1,
    'Blank Scribes Created': 1
  });
  yield call(incrementEventSuperProperty, 'Total Scribes Created');
  yield call(incrementEventSuperProperty, 'Blank Scribes Created');
}

function* trackNewScribeClicked({ eventTrigger }) {
  yield call(track, 'Create New Scribe', {
    'Event Trigger': eventTrigger
  });
}

function* trackLocationChange({ payload }) {
  const { pathname } = payload.location;
  const isTemplateCategoryRoute = matchPath(pathname, { path: ROUTE_CODES.TEMPLATES_CATEGORY, exact: true }) !== null;
  switch (true) {
    case isTemplateCategoryRoute:
      return yield call(track, 'Select Templates Tab');
    case pathname === ROUTE_CODES.HOME:
      return yield call(track, 'Select My Scribes Tab');
    default:
      return;
  }
}

function* trackTemplatePreviewClick({ template, category }) {
  yield call(track, 'Preview Template', {
    'Template Title': template.title,
    'Template Category': category
  });
}

function* trackTemplateScroll({ category }) {
  yield call(track, 'Scroll Templates', {
    'Template Category': category
  });
}

function* trackTemplateEdit({ title, category, triggerLocation, sparkolTemplateId }) {
  const eventProperties = {
    'Template Title': title,
    'Template Category': category,
    'Button Location': triggerLocation
  };

  yield call(track, 'Template Requested', eventProperties);

  if (sparkolTemplateId) {
    yield trackServicesTemplateLoadedSaga(eventProperties);
  } else {
    yield trackTemplateLoadedSaga(eventProperties);

    const { scribe } = yield take(ADD_SCRIBE_SUCCESS);

    yield call(track, 'Edit Template', {
      ...eventProperties,
      'Scribe ID': scribe.id
    });
  }
}

function* trackUpgradeAccountFromTemplate() {
  yield take(LOCATION_CHANGE); // Discard first LOCATION_CHANGE action
  yield take(LOCATION_CHANGE); // Leaving the modal
  yield put(leaveTemplatePreview());
}

function* trackTemplateEditFromPreview({ template, category }) {
  yield take(LOCATION_CHANGE); // Discard first LOCATION_CHANGE action
  const nextAction = yield take([CREATE_SCRIBE_FROM_TEMPLATE]);

  if (nextAction.type === CREATE_SCRIBE_FROM_TEMPLATE) {
    yield call(trackTemplateEdit, {
      title: template.title,
      category,
      triggerLocation: 'Template Preview Modal',
      sparkolTemplateId: template.sparkolTemplateId
    });
  }
}

function* trackDownloadFormatSelectionChange({ format, scribe }) {
  const fileType = getFileTypeFromFormat(format);
  yield call(track, `Select ${fileType} Format`, {
    'Scribe ID': scribe.id,
    'Scribe Type': scribe.source?.type || 'unknown'
  });
}

function* trackCursorSelectionChange({ cursorId, scribeId }) {
  const cursor = yield select(state => {
    const cursors = getAllCursors(state);
    return cursors.find(c => c.id === cursorId);
  });
  yield call(track, 'Select Default Cursor', {
    'Cursor Category': cursor?.group || 'No category',
    'Cursor Name': cursor?.name || 'No hand',
    'Scribe ID': scribeId
  });
}

function* trackCursorFilterChange({ category, scribeId }) {
  yield call(track, 'Select Default Cursor Category', { 'Cursor Category': category, 'Scribe ID': scribeId });
}

function* trackUpdateScribeCursor({ cursorId, scribeId }) {
  const cursor = yield select(state => {
    const cursors = getAllCursors(state);
    return cursors.find(c => c.id === cursorId);
  });
  yield call(track, 'Confirm Default Cursor Selection', {
    'Cursor Category': cursor?.group || 'No category',
    'Cursor Name': cursor?.name || 'No hand',
    'Scribe ID': scribeId
  });
}

function* trackCreateShapeElement({ shapeType, scribeId }) {
  yield call(track, 'Add Shape To Canvas', { Shape: shapeType, 'Scribe ID': scribeId });
}

function* trackUpdateScribeBackgroundSettings({ scribeId, colorType, gradientType }) {
  yield call(track, 'Update Background Settings', {
    'Scribe ID': scribeId,
    'Colour Type': colorType,
    'Gradient Type': gradientType
  });
}

function* trackAnimationTypeChange({ animationType, stage, scribeId }) {
  if (stage === 'entrance') {
    yield call(track, 'Set Entrance Animation', {
      'Entrance Animation': animationType,
      'Scribe ID': scribeId
    });
  }

  if (stage === 'emphasis') {
    yield call(track, 'Set Emphasis Animation', {
      'Emphasis Animation': animationType,
      'Scribe ID': scribeId
    });
  }

  if (stage === 'exit') {
    yield call(track, 'Set Exit Animation', {
      'Exit Animation': animationType,
      'Scribe ID': scribeId
    });
  }
}

function* trackAnimationDirectionChange({ animationType, stage, scribeId, newTweenPosition, spinRotation }) {
  const selectedAnimationOption = selectableEntranceAnimationOptions.find(
    opt => opt.value !== undefined && opt.value === newTweenPosition
  );

  if (stage === 'entrance') {
    yield call(track, 'Set Animation Direction', {
      'Entrance Animation': animationType,
      'Animation Direction': selectedAnimationOption?.label,
      'Scribe ID': scribeId
    });
  }

  if (stage === 'emphasis') {
    if (!spinRotation) {
      const selectedAnimationOption = selectableShakeAnimationOptions.find(
        opt => opt.direction !== undefined && opt.direction === newTweenPosition
      );

      yield call(track, 'Set Animation Direction', {
        'Emphasis Animation': animationType,
        'Animation Direction': selectedAnimationOption?.label,
        'Scribe ID': scribeId
      });
    } else {
      yield call(track, 'Set Animation Direction', {
        'Emphasis Animation': animationType,
        'Animation Direction': spinRotation === 360 ? 'Clockwise' : 'Counter Clockwise',
        'Scribe ID': scribeId
      });
    }
  }

  if (stage === 'exit') {
    yield call(track, 'Set Animation Direction', {
      'Exit Animation': animationType,
      'Animation Direction': selectedAnimationOption?.label,
      'Scribe ID': scribeId
    });
  }
}

function* trackNumberOfScribes() {
  const numberOfScribes = yield select(({ scribes }) => scribes.homepageProjects.length);

  if (numberOfScribes === undefined) {
    return;
  }
  yield call(setUserProperties, 'Current Number of Projects', numberOfScribes);
}

function* trackFullscreenEntered({ scribeId, eventTrigger, previewLocation, previewType }) {
  yield call(track, 'Preview Enter Full Screen', {
    'Scribe ID': scribeId,
    'Event Trigger': eventTrigger,
    'Preview Location': previewLocation,
    'Preview Type': previewType
  });
}

function* trackSelectAll({ scribeId, eventTrigger, hasScenesSelected }) {
  yield call(track, 'Select All', {
    [`Select All ${hasScenesSelected ? 'Scenes' : 'Elements'}`]: true,
    'Scribe ID': scribeId,
    'Event Trigger': eventTrigger
  });
}

function* trackSetActiveElementsVisibility({ scribeId, elementIds, eventTrigger, eventName }) {
  if (elementIds.length > 1) {
    yield call(track, eventName, { 'Scribe ID': scribeId, 'Element Type': 'Multiple', 'Event Trigger': eventTrigger });
  } else {
    const scribe = yield select(state => getScribeById(state, scribeId));
    const element = scribe.elements.find(el => el.id === elementIds[0]);
    if (element) {
      yield call(track, eventName, {
        'Scribe ID': scribeId,
        'Element Type': element.type,
        'Event Trigger': eventTrigger
      });
    }
  }
}

function* trackUpdateProjectTitleTrigger({ scribeId, eventTrigger }) {
  yield call(track, 'Rename Project', {
    'Scribe ID': scribeId,
    'Rename Location': eventTrigger
  });
}
function* trackCameraWarningModal({ scribeId }) {
  yield call(track, 'Camera Warning Modal Shown', {
    'Scribe ID': scribeId
  });
}

export default function* mixpanelSagas() {
  yield takeEvery([VALIDATE_TOKEN_SUCCESS], trackLogin);
  yield takeEvery(TRACK_PERSONA_ANSWERS, trackPersonaAnswers);
  yield takeEvery(SHOW_USERPERSONA_MODAL, trackPersonaModalOpen);
  yield takeEvery(TRACK_OPEN_ACCOUNT_MANAGEMENT, trackOpenAccountManagement);
  yield takeEvery(ADD_SCRIBE_SUCCESS, trackNewScribeCreated);
  yield takeEvery(LOCATION_CHANGE, trackLocationChange);
  yield takeEvery(TRACK_TEMPLATE_PREVIEW_CLICK, trackTemplatePreviewClick);
  yield takeEvery(TRACK_TEMPLATE_SCROLL, trackTemplateScroll);
  yield takeEvery(TRACK_TEMPLATE_EDIT, trackTemplateEdit);
  yield takeEvery(TRACK_TEMPLATE_PREVIEW_CLICK, trackTemplateEditFromPreview);
  yield takeEvery(TRACK_TEMPLATE_PREVIEW_CLICK, trackUpgradeAccountFromTemplate);
  yield takeEvery(TRACK_DOWNLOAD_FORMAT_SELECTION_CHANGE, trackDownloadFormatSelectionChange);
  yield takeEvery(TRACK_CURSOR_SELECTION_CHANGE, trackCursorSelectionChange);
  yield takeEvery(TRACK_CURSOR_FILTER_CHANGE, trackCursorFilterChange);
  yield takeEvery(UPDATE_SCRIBE_CURSOR, trackUpdateScribeCursor);
  yield takeEvery(CREATE_SHAPE_ELEMENT, trackCreateShapeElement);
  yield takeEvery(DUPLICATE_SCRIBE, trackDuplicateScribeSaga);
  yield takeEvery(SET_ANIMATION_TYPE, trackAnimationTypeChange);
  yield takeEvery([SET_ANIMATION_DIRECTION, SET_EMPHASIS_ANIMATION_DIRECTION], trackAnimationDirectionChange);
  yield takeEvery([ADD_SCRIBE_SUCCESS, LOAD_SCRIBES_SUCCESS, DUPLICATE_SCRIBE_SUCCESS], trackNumberOfScribes);
  yield takeEvery(TRACK_UPDATE_SCRIBE_BACKGROUND_SETTINGS, trackUpdateScribeBackgroundSettings);
  yield takeEvery(TRACK_FULLSCREEN_ENTERED, trackFullscreenEntered);
  yield takeEvery(TRACK_SELECT_ALL, trackSelectAll);
  yield takeEvery(TRACK_SET_ACTIVE_ELEMENTS_VISIBILITY, trackSetActiveElementsVisibility);
  yield takeEvery(UPDATE_PROJECT_TITLE, trackUpdateProjectTitleTrigger);
  yield takeEvery(SET_PROJECTS_VIEW_MODE, trackSetProjectsViewMode);
  yield takeEvery(TRACK_CREATE_NEW_PROJECT, trackNewScribeClicked);
  yield takeEvery(TRACK_CAMERA_WARNING_MODAL, trackCameraWarningModal);

  yield fork(trackDownloadRequestsSagas);
  yield fork(trackImagesSagas);
  yield fork(trackUiSagas);
  yield fork(trackAudioSagas);
  yield fork(trackTimelineSagas);
  yield fork(trackScribeListSagas);
  yield fork(trackCanvasEvents);
  yield fork(trackScenesSagas);
  yield fork(trackImageCroppingSagas);
  yield fork(trackElementCursorSelectionSagas);
  yield fork(trackUserFontsSaga);
  yield fork(trackPreviewSagas);
  yield fork(manageTimelineSuperEventProperties);
  yield fork(trackCameraSagas);
  yield fork(trackElementAlignment);
  yield fork(trackAudioLibrarySagas);
  yield fork(trackSubscriptionSagas);
  yield fork(trackLimitsSagas);
  yield fork(trackAiSagas);
  yield fork(trackImageDrawerSagas);
}
