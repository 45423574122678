import { call, CallEffect, put, PutEffect, takeEvery } from 'redux-saga/effects';
import {
  SyncScriptAction,
  SYNC_SCRIPT,
  fetchScriptRequested,
  FETCH_SCRIPT_REQUESTED,
  fetchScriptFailed,
  fetchScriptSucceeded,
  FetchScriptRequestedAction
} from 'js/actionCreators/projectScriptActions';
import {
  DELETE_SCRIBE_SUCCESS,
  DeleteScribeSuccessAction,
  SET_ACTIVE_SCRIBE,
  SetActiveScribeAction
} from 'js/actionCreators/scribeActions';
import { showError } from 'js/actionCreators/uiActions';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import getOptimizelyInstance from 'js/shared/helpers/optimizely';
import { SCRIPT_GENERATION } from 'js/config/featureKeys';

function* syncScript(action: SyncScriptAction) {
  const { projectId, script } = action;

  try {
    yield call(appServices.putProjectScript, projectId, script);
  } catch (error) {
    console.error(error);
    yield put(
      showError({
        message: 'Failed to save script',
        description: 'An error occurred while saving the script. Please try again.'
      })
    );
  }
}

function* loadScript(action: SetActiveScribeAction) {
  const optimizely = getOptimizelyInstance();

  if (optimizely.isFeatureEnabled(SCRIPT_GENERATION)) {
    const { scribeId: projectId } = action;

    yield put(fetchScriptRequested(projectId));
  }
}

function* fetchScript(
  action: FetchScriptRequestedAction
): Generator<CallEffect | PutEffect, void, { projectScript: string }> {
  const { projectId } = action;

  try {
    const response = yield call(appServices.getProjectScript, projectId);
    if ('projectScript' in response) {
      yield put(fetchScriptSucceeded(projectId, response.projectScript));
    }
  } catch (error) {
    console.error(error);
    if (error instanceof Error) {
      yield put(fetchScriptFailed(projectId, error));
    }
  }
}

function* deleteScript(action: DeleteScribeSuccessAction) {
  const { scribeId: projectId } = action;
  try {
    yield call(appServices.deleteProjectScript, projectId);
  } catch (error) {
    console.error(error);
    yield put(
      showError({
        message: 'Failed to delete script',
        description: 'An error occurred while deleting the script. Please try again.'
      })
    );
  }
}

export default function* projectScriptsSagas() {
  yield takeEvery(SET_ACTIVE_SCRIBE, loadScript);
  yield takeEvery(SYNC_SCRIPT, syncScript);
  yield takeEvery(FETCH_SCRIPT_REQUESTED, fetchScript);
  yield takeEvery(DELETE_SCRIBE_SUCCESS, deleteScript);
}
