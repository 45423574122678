import { call, takeEvery, take } from 'redux-saga/effects';
import {
  ADD_USER_UPLOAD_IMAGE_TO_CANVAS,
  ADD_USER_IMAGE_TO_SCRIBE,
  ADD_LIBRARY_IMAGE_TO_CANVAS,
  UPLOAD_IMAGE_SUCCESS
} from 'js/actionCreators/imagesActions';
import {
  TRACK_IMAGE_IMPORT_CLICK,
  TRACK_IMAGE_LIBRARY_CLICK,
  TRACK_IMAGE_PROVIDER_CHANGE,
  TRACK_IMAGE_REPLACE,
  TRACK_IMAGE_REPLACE_MODAL,
  TRACK_IMAGE_SEARCH,
  TRACK_CLICK_ENABLE_COLOUR_CHANGE_ON_IMAGE,
  TRACK_ENABLE_COLOUR_CHANGE_INFO_MODAL_DISPLAYED,
  TRACK_CONFIRM_COLOUR_CHANGE_ON_IMAGE,
  TRACK_IMAGE_COLOUR_CHANGE_SUCCESSFUL
} from 'js/actionCreators/trackingActions';
import { CREATE_IMAGE_ELEMENT } from 'js/actionCreators/scribeActions';
import { userImageLibrary } from 'js/shared/resources/userImageLibrary';
import { AddAIGenerationTrigger } from 'js/types';

import { track } from '../mixpanelProvider';

import { getImageGenerationBasicData } from './trackAiSagas';

const GIVE_UP_AFTER_NUMBER_ELEMENTS_CREATED = 5;

function* trackClickEnableColourChangeOnImage({ scribeId }) {
  yield call(track, 'Click Enable Colour Change On Image', { 'Scribe ID': scribeId });
}

function* trackEnableColourChangeInfoModalDisplayed({ scribeId }) {
  yield call(track, 'Enable Colour Change Info Modal Displayed', { 'Scribe ID': scribeId });
}

function* trackConfirmColourChangeOnImage({ scribeId }) {
  yield call(track, 'Confirm Colour Change On Image', { 'Scribe ID': scribeId });
}

function* trackImageColourChangeSuccessful({ scribeId }) {
  yield call(track, 'Image Colour Change Successful', { 'Scribe ID': scribeId });
}

function* trackImageElementCreatedFromUpload({ file, scribeId, uploadMethod }) {
  const props = {
    'Image Name': file.name,
    'Image Source': 'user',
    'Scribe ID': scribeId,
    'File Type': file.type,
    'Image size': file.size,
    'Upload Method': uploadMethod
  };
  yield call(track, 'Add Image To Canvas', props);
}

function* trackImageElementCreatedFromLibrary({
  scribeId,
  imageType,
  imageId,
  selectedLibrary,
  filename,
  rating,
  eventTrigger
}) {
  let imageElementData;
  let counter = 0;

  while (true) {
    counter++;
    // Give up after while as something will have gone wrong with processing the image
    if (counter > GIVE_UP_AFTER_NUMBER_ELEMENTS_CREATED) {
      return;
    }
    const { initialData } = yield take(CREATE_IMAGE_ELEMENT);
    if (initialData.image?.assetId === imageId) {
      imageElementData = initialData;
      break;
    }
  }

  let aiGeneratedTrackingProps = {};
  if (eventTrigger === AddAIGenerationTrigger.AI_LHP) {
    const aiImageBasicData = yield call(getImageGenerationBasicData);
    if (aiImageBasicData) {
      aiGeneratedTrackingProps = {
        ...aiImageBasicData,
        'Image Rating': rating,
        'Image Source': eventTrigger,
        'Image ID': imageId,
        'Image Source ID': aiImageBasicData['Image ID']
      };
    }
  }

  const props = {
    'Image Name': filename,
    'Image ID': imageId,
    'Image Source': eventTrigger,
    'Scribe ID': scribeId,
    'File Type': imageType,
    Category: selectedLibrary?.title || userImageLibrary,
    'Colour Change': imageElementData.recolorAvailable,
    ...aiGeneratedTrackingProps
  };
  yield call(track, 'Add Image To Canvas', props);
}

function* trackImageImportClick({ scribeId }) {
  yield call(track, 'Open Image Upload', { 'Scribe ID': scribeId });
}

function* trackImageLibraryClick({ library, scribeId, trigger }) {
  yield call(track, 'Select Image Category', {
    'Scribe ID': scribeId,
    Category: library.title,
    'Event Trigger': trigger
  });
}

function* trackImageProviderChange({ provider, libraryState }) {
  const tab = provider === 'noun-project' ? 'Noun Project' : 'VideoScribe';
  yield call(track, 'Select Image Tab', { 'Image Library State': libraryState, Tab: tab });
}

function* trackSearchImage({ query, scribeId, tab }) {
  yield call(track, 'Search For Image', { 'Scribe ID': scribeId, 'Search Term': query, Tab: tab });
}

function* trackImageElementAddedFromLibrary({ scribeId, imageType, provider, imageId, selectedLibrary, filename }) {
  let imageElementData;
  let counter = 0;
  while (true) {
    counter++;
    // Give up after while as something will have gone wrong with processing the image
    if (counter > GIVE_UP_AFTER_NUMBER_ELEMENTS_CREATED) {
      return;
    }
    const { initialData } = yield take(CREATE_IMAGE_ELEMENT);
    if (initialData.image?.assetId === imageId) {
      imageElementData = initialData;
      break;
    }
  }

  const props = {
    'Image Name': filename,
    'Image ID': imageId,
    'Image Source': provider,
    'Scribe ID': scribeId,
    'File Type': imageType,
    Category: selectedLibrary || userImageLibrary,
    'Colour Change': imageElementData.recolorAvailable
  };
  yield call(track, 'Add Image To Canvas', props);
}

function* trackReplaceImageOpened({ eventTrigger, scribeId }) {
  yield call(track, 'Open Image Replace Modal', { 'Scribe ID': scribeId, 'Event Trigger': eventTrigger });
}

function* trackReplaceImage({
  scribeId,
  imageType,
  provider,
  imageId,
  selectedLibrary,
  filename,
  recolorAvailable,
  imageSize,
  temporaryAssetId
}) {
  let imageElementData;
  if (!imageId) {
    let counter = 0;
    while (true) {
      counter++;
      // Give up after while as something will have gone wrong with processing the image
      if (counter > 5) {
        return;
      }
      const action = yield take(UPLOAD_IMAGE_SUCCESS);
      if (action?.originalAssetId === temporaryAssetId) {
        imageElementData = action?.assetId;
        break;
      }
    }
  }

  yield call(track, 'Replace Image on Canvas', {
    'Image Name': filename,
    'Image ID': imageId || imageElementData,
    'Image Source': provider,
    'Scribe ID': scribeId,
    'File Type': imageType,
    Category: selectedLibrary?.title,
    'Colour Change': recolorAvailable,
    'Image Size': imageSize
  });
}

export default function* trackImagesSagas() {
  yield takeEvery(TRACK_IMAGE_IMPORT_CLICK, trackImageImportClick);
  yield takeEvery(ADD_USER_UPLOAD_IMAGE_TO_CANVAS, trackImageElementCreatedFromUpload);
  yield takeEvery(ADD_USER_IMAGE_TO_SCRIBE, trackImageElementCreatedFromLibrary);
  yield takeEvery(ADD_LIBRARY_IMAGE_TO_CANVAS, trackImageElementAddedFromLibrary);
  yield takeEvery(TRACK_IMAGE_LIBRARY_CLICK, trackImageLibraryClick);
  yield takeEvery(TRACK_IMAGE_PROVIDER_CHANGE, trackImageProviderChange);
  yield takeEvery(TRACK_IMAGE_SEARCH, trackSearchImage);
  yield takeEvery(TRACK_IMAGE_REPLACE_MODAL, trackReplaceImageOpened);
  yield takeEvery(TRACK_IMAGE_REPLACE, trackReplaceImage);
  yield takeEvery(TRACK_CLICK_ENABLE_COLOUR_CHANGE_ON_IMAGE, trackClickEnableColourChangeOnImage);
  yield takeEvery(TRACK_ENABLE_COLOUR_CHANGE_INFO_MODAL_DISPLAYED, trackEnableColourChangeInfoModalDisplayed);
  yield takeEvery(TRACK_CONFIRM_COLOUR_CHANGE_ON_IMAGE, trackConfirmColourChangeOnImage);
  yield takeEvery(TRACK_IMAGE_COLOUR_CHANGE_SUCCESSFUL, trackImageColourChangeSuccessful);
}
