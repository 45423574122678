import set from 'lodash.set';
import {
  CHANGE_AI_PANEL_TAB,
  ChangeAiPanelTabAction,
  GENERATE_IMAGES,
  GenerateImagesAction,
  RESET_IMAGE_GENERATION,
  ResetImageGenerationAction,
  IMAGE_GENERATION_SUCCESS,
  IMAGE_GENERATION_FAILED,
  ImageGenerationSuccessAction,
  ImageGenerationFailedAction,
  REGENERATE_IMAGES,
  RegenerateImagesAction,
  SetImageGenerationInputsAction,
  SET_IMAGE_GENERATION_INPUTS,
  SET_VOICEOVER_GENERATION_INPUTS,
  SetVoiceoverGenerationInputsAction,
  GENERATE_VOICEOVER,
  GenerateVoiceoverAction,
  VOICEOVER_GENERATION_SUCCESS,
  VOICEOVER_GENERATION_FAILED,
  VoiceoverGenerationSuccessAction,
  VoiceoverGenerationFailedAction,
  RESET_VOICEOVER_GENERATION,
  ResetVoiceoverGenerationAction,
  EDIT_VOICEOVER,
  EditVoiceoverAction,
  RATE_VOICEOVER_GENERATION_RESULT,
  RateVoiceoverGenerationResultAction,
  EDIT_IMAGE_PROMPT,
  EditImagePromptAction,
  CHANGE_AI_PANEL_STEP,
  ChangeAiPanelStepAction,
  SET_SCRIPT_GENERATION_INPUTS,
  SetScriptGenerationInputsAction,
  GENERATE_SCRIPT,
  GenerateScriptAction,
  RESET_SCRIPT_GENERATION,
  EDIT_GENERATED_SCRIPT,
  ResetScriptGenerationAction,
  GENERATE_SCRIPT_SUCCESS,
  GENERATE_SCRIPT_FAILED,
  GenerateScriptFailedAction,
  GenerateScriptSuccessAction,
  SetGenerationFeedbackAction,
  SET_GENERATION_FEEDBACK,
  SET_TAGS,
  SetTagsAction,
  SET_FEEDBACK_COMMENTS,
  SetFeedbackCommentsAction,
  EditGeneratedScriptAction
} from 'js/actionCreators/aiFeaturesActions';
import { SHOW_LEFT_HAND_PANEL, ShowLeftHandPanelAction } from 'js/actionCreators/uiActions';
import { AI_FEATURES } from 'js/config/config';
import {
  aiFeaturesState,
  AiFeaturesTabStep,
  AiImageGenerationTabStep,
  AiPanelTab,
  AiScriptGenerationTabStep,
  AiVoiceGenerationTabStep,
  ImageGenerationStatus,
  VoiceoverGenerationStatus,
  LeftHandPanel
} from 'js/types';

type BetaAiFeaturesActionTypes =
  | ChangeAiPanelTabAction
  | GenerateImagesAction
  | ResetImageGenerationAction
  | ImageGenerationSuccessAction
  | ImageGenerationFailedAction
  | RegenerateImagesAction
  | SetImageGenerationInputsAction
  | SetVoiceoverGenerationInputsAction
  | GenerateVoiceoverAction
  | VoiceoverGenerationSuccessAction
  | VoiceoverGenerationFailedAction
  | ResetVoiceoverGenerationAction
  | EditVoiceoverAction
  | ShowLeftHandPanelAction
  | RateVoiceoverGenerationResultAction
  | EditImagePromptAction
  | ChangeAiPanelStepAction
  | SetScriptGenerationInputsAction
  | GenerateScriptAction
  | ResetScriptGenerationAction
  | GenerateScriptSuccessAction
  | GenerateScriptFailedAction
  | SetGenerationFeedbackAction
  | GenerateScriptFailedAction
  | SetTagsAction
  | SetFeedbackCommentsAction
  | SetImageGenerationInputsAction
  | EditGeneratedScriptAction;

export const initialState: aiFeaturesState = {
  panelTab: AiPanelTab.FEATURES,
  tabState: {
    [AiPanelTab.FEATURES]: {
      step: AiFeaturesTabStep.OVERVIEW
    },
    [AiPanelTab.IMAGE_GENERATION]: {
      step: AiImageGenerationTabStep.OVERVIEW
    },
    [AiPanelTab.VOICE_GENERATION]: {
      step: AiVoiceGenerationTabStep.OVERVIEW
    },
    [AiPanelTab.SCRIPT_GENERATION]: {
      step: AiScriptGenerationTabStep.OVERVIEW
    }
  },
  imageGenerationInputs: {
    style: undefined,
    prompt: ''
  },
  imageGenerationStatus: ImageGenerationStatus.NOT_STARTED,
  imageGenerationResults: {
    images: [],
    error: null,
    id: null,
    enhancedPrompt: null
  },
  voiceoverGenerationInputs: {
    prompt: '',
    language: undefined,
    voice: undefined
  },
  voiceoverGenerationStatus: VoiceoverGenerationStatus.NOT_STARTED,
  voiceoverGenerationResults: {
    url: '',
    id: null,
    error: null,
    assetId: null,
    rating: null
  },
  scriptGenerationInputs: {
    prompt: '',
    predefinedStyles: [],
    customStyle: '',
    lengthOfScriptMinutes: AI_FEATURES.SCRIPT.DEFAULT_SCRIPT_LENGTH_MINUTES,
    lengthOfScriptSeconds: 0,
    numberOfScenes: AI_FEATURES.SCRIPT.DEFAULT_NUMBER_OF_SCENES
  },
  scriptGenerationLoading: false,
  scriptGenerationResults: {
    script: ''
  },
  feedbackRatings: {
    voiceover: undefined,
    script: undefined
  },
  feedbackTags: {
    voiceover: [],
    script: []
  },
  feedbackComments: {
    voiceover: '',
    script: ''
  }
};

export default function aiFeaturesReducer(state = initialState, action: BetaAiFeaturesActionTypes): aiFeaturesState {
  switch (action.type) {
    case CHANGE_AI_PANEL_TAB: {
      return {
        ...state,
        panelTab: action.tab
      };
    }

    case CHANGE_AI_PANEL_STEP: {
      return {
        ...state,
        tabState: {
          ...state.tabState,
          [action.tab]: {
            step: action.step
          }
        }
      };
    }

    case SHOW_LEFT_HAND_PANEL: {
      const { name } = action;
      if (name !== LeftHandPanel.AI_GENERATION) return state;
      const { tab } = action;
      return {
        ...state,
        ...(tab && { panelTab: tab })
      };
    }

    case GENERATE_IMAGES: {
      return {
        ...state,
        imageGenerationStatus: ImageGenerationStatus.IN_PROGRESS,
        imageGenerationResults: initialState.imageGenerationResults
      };
    }

    case REGENERATE_IMAGES: {
      return {
        ...state,
        imageGenerationStatus: ImageGenerationStatus.IN_PROGRESS
      };
    }

    case IMAGE_GENERATION_SUCCESS: {
      return {
        ...state,
        imageGenerationStatus: ImageGenerationStatus.COMPLETE,
        imageGenerationResults: {
          images: action.images,
          error: null,
          id: action.imageGenerationId,
          enhancedPrompt: action.enhancedPrompt
        }
      };
    }

    case IMAGE_GENERATION_FAILED: {
      return {
        ...state,
        imageGenerationStatus: ImageGenerationStatus.FAILED,
        imageGenerationResults: {
          images: [],
          error: action.error,
          id: null,
          enhancedPrompt: null
        }
      };
    }

    case RESET_IMAGE_GENERATION: {
      return {
        ...state,
        imageGenerationInputs: initialState.imageGenerationInputs,
        imageGenerationStatus: initialState.imageGenerationStatus,
        imageGenerationResults: initialState.imageGenerationResults
      };
    }

    case EDIT_IMAGE_PROMPT: {
      return {
        ...state,
        imageGenerationStatus: ImageGenerationStatus.NOT_STARTED,
        imageGenerationResults: initialState.imageGenerationResults
      };
    }

    case SET_IMAGE_GENERATION_INPUTS: {
      return {
        ...state,
        imageGenerationInputs: {
          ...state.imageGenerationInputs,
          ...action
        }
      };
    }

    case SET_VOICEOVER_GENERATION_INPUTS: {
      return {
        ...state,
        voiceoverGenerationInputs: {
          ...state.voiceoverGenerationInputs,
          ...action
        }
      };
    }

    case GENERATE_VOICEOVER: {
      return {
        ...state,
        voiceoverGenerationStatus: VoiceoverGenerationStatus.PENDING,
        voiceoverGenerationResults: initialState.voiceoverGenerationResults
      };
    }

    case VOICEOVER_GENERATION_SUCCESS: {
      return {
        ...state,
        voiceoverGenerationStatus: VoiceoverGenerationStatus.SUCCEEDED,
        voiceoverGenerationResults: {
          assetId: action.voiceoverAssetId,
          url: action.voiceoverUrl,
          id: action.voiceoverId,
          error: null,
          rating: null
        }
      };
    }

    case VOICEOVER_GENERATION_FAILED: {
      return {
        ...state,
        voiceoverGenerationStatus: VoiceoverGenerationStatus.FAILED,
        voiceoverGenerationResults: {
          assetId: null,
          url: null,
          id: null,
          error: action.error,
          rating: null
        }
      };
    }

    case RESET_VOICEOVER_GENERATION: {
      return {
        ...state,
        voiceoverGenerationInputs: initialState.voiceoverGenerationInputs,
        voiceoverGenerationStatus: initialState.voiceoverGenerationStatus,
        voiceoverGenerationResults: initialState.voiceoverGenerationResults
      };
    }

    case EDIT_VOICEOVER: {
      return {
        ...state,
        voiceoverGenerationStatus: VoiceoverGenerationStatus.NOT_STARTED,
        voiceoverGenerationResults: initialState.voiceoverGenerationResults
      };
    }

    case RATE_VOICEOVER_GENERATION_RESULT: {
      return {
        ...state,
        voiceoverGenerationResults: {
          ...state.voiceoverGenerationResults,
          rating: action.rating
        }
      };
    }

    case SET_SCRIPT_GENERATION_INPUTS: {
      return {
        ...state,
        scriptGenerationInputs: {
          ...state.scriptGenerationInputs,
          ...action
        }
      };
    }

    case GENERATE_SCRIPT: {
      return {
        ...state,
        scriptGenerationLoading: true,
        scriptGenerationResults: {
          script: ''
        }
      };
    }

    case EDIT_GENERATED_SCRIPT: {
      return {
        ...state,
        scriptGenerationResults: {
          script: action.script
        }
      };
    }

    case RESET_SCRIPT_GENERATION: {
      return {
        ...state,
        scriptGenerationInputs: initialState.scriptGenerationInputs,
        scriptGenerationResults: {
          script: ''
        }
      };
    }

    case GENERATE_SCRIPT_SUCCESS: {
      return {
        ...state,
        scriptGenerationLoading: false,
        scriptGenerationResults: {
          script: action.script
        }
      };
    }

    case GENERATE_SCRIPT_FAILED: {
      return {
        ...state,
        scriptGenerationLoading: false
      };
    }

    case SET_GENERATION_FEEDBACK: {
      const newState = { ...state };
      set(newState, `feedbackRatings.${action.updatePath}`, action.feedback);
      return newState;
    }

    case SET_TAGS: {
      const newState = { ...state };
      set(newState, action.statePath, action.tags);
      return newState;
    }

    case SET_FEEDBACK_COMMENTS: {
      const newState = { ...state };
      set(newState, `feedbackComments.${action.updatePath}`, action.comments);
      return newState;
    }

    default:
      return state;
  }
}
